import { createTheme } from '@mui/material';

export const themeBreakPoints = {xs:'100vw',sm:'100vw',md:'90vw',lg:'70vw',xl:'70vw'};

export const fabStyle = {
        position: 'fixed',
        bottom: 80,
        left: 24,

}

export const colorPackTwo = {
        //purples
        primaryDark: '#861854',
        primaryMain: '#b34387',
        primaryLight: '#a97eaf',
        //blue
        secondaryDark: '#007f9d',
        secondaryMain: '#14a5bb',
        secondaryLight: '#58b6c8',
        //violet
        contrastDark: '#784984',
        contrastMain: '#966aa1',
        contrastLight: '#c08db4',
}

export const colorPack = {
        white: '#ffffff',
        //purples
        primaryDark: '#861854',
        primaryMain: '#b34387',
        primaryLight: '#a97eaf',
        //blue
        secondaryDark: '#007f9d',
        secondaryMain: '#14a5bb',
        secondaryLight: '#58b6c8',
        // violet
        contrastDark: '#784984',
        contrastMain: '#966aa1',
        contrastLight: '#c08db4',
        
        lightText: '#939393',
        mainText: '#474747',
        darkText: '#070707',
        gradient: `linear-gradient(0deg, rgba(193,213,224,0.5) 0%, rgba(144,164,174,0.5) 100%);`, //use with css 'background' attribute 
}

export const theme = createTheme({
       
        spacing: 8,
   
        palette: {      
                        text: { 
                                light: '#62727b',
                                main: '#37474f',
                                dark: '#102027',
                                error: '#8c0032',
                                linkColor: '#005b9f',
                                warning: '#c2185b',
                                white: '#ffffff',
                                black: '#000000',
                        },
                        background: { 
                                default: '#ffffff',
                                tint: '#c4c4c4',
                                tintLight: '#ededed',
                        },
                        primary: { 
                                light: '#d683a1',
                                main:'#a10355',
                                dark: '#7a0040',
                                contrast: '000000',
                        },
                        secondary: { 
                        light: '#c1d5e0',
                        main: '#90a4ae',
                        dark: '#62757f',
                        contrast: '#000000',
                        },
        },
    overrides: {  
        MuiFab: {
                primary:{
                        backgroundColor: 'blue',
                }
        },
                        
        MuiPaper:{
                root:{
                        padding: 0,
                        margin: 0,
                        textDecoration: 'none', 
                },
        },


        MuiTypography:{
                        button: {
                                fontFamily:'Roboto, sans-serif',
                                fontWeight: '500',
                                color: '#141414',
                                fontSize: '0.875rem',
                                letterSpacing: '0.078rem',
                                lineHeight: 1,
                                textDecoration: 'none',
                                textTransform: 'uppercase',
                        },
                        body1: {
                                fontSize: '1rem',
                                fontFamily:'Roboto, sans-serif',
                                fontWeight: '400',
                                color: '#141414',
                                letterSpacing: ".031rem",
                                lineHeight: 1.45,
                                },
                        body2: {
                                fontFamily:'Roboto, sans-serif',
                                fontWeight: '100',
                                color: '#141414',
                                fontSize: '0.90rem',
                                letterSpacing: ".015rem",
                                lineHeight: 1,
                        },
                        subtitle1: {
                                fontFamily:'Roboto, sans-serif',
                                fontWeight: '400',
                                color: '#6e6d6d',
                                fontSize: '1.15rem',
                                letterSpacing: '0.009rem',
                                lineHeight: 1.15,
                        },
                        subtitle2: {
                                fontFamily:'Roboto, sans-serif',
                                fontWeight: '500',
                                color: '#141414',
                                fontSize: '1.05rem',
                                letterSpacing: '0.078rem',
                                lineHeight: 1.15,
                        },
                        caption: {
                                fontFamily:'Roboto, sans-serif',
                                fontWeight: '400',
                                color: '#141414',
                                fontSize: '0.975rem',
                                letterSpacing: '0.078rem',
                                lineHeight: 1.15,  
                                textDecoration: 'none',
                        },
                        h1: {
                                fontWeight: '300',
                                fontSize: '3.45rem',
                                letterSpacing: '-0.094rem',
                                color: '#141414',
                                lineHeight: 1.15,
                        },
                        h2: {
                                fontFamily: 'font-family:Marhey, cursive', // !! Not working, need to debug !!
                                fontWeight: '300',
                                fontSize: '2.75rem',  
                                letterSpacing: '-0.031rem',
                                color: '#141414',
                                lineHeight: 1.15,
                        },
                        h3: {
                                fontWeight: '300',
                                fontSize: '1.75rem',
                                letterSpacing: '0rem',
                                color: '#141414',
                                lineHeight: 1.15,
                        },
                        h4: {
                                fontWeight: '300',
                                fontSize: '1.15rem',
                                letterSpacing: '.016rem',
                                color: '#141414',
                                lineHeight: 1.15,
                        },
                        h5: {
                                fontWeight: '200',
                                fontSize: '0.95rem',
                                letterSpacing: '.015rem',
                                color: '#141414',
                                lineHeight: 1.15,
                        },
                        h6: {
                                fontWeight: '200',
                                fontSize: '0.90rem',
                                letterSpacing: '.009rem',
                                color: '#141414',
                                lineHeight: 1.15,
                        },
                },
                
        }
});

export default theme;