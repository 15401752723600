import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { theme } from './theme/theme';
import router from './router';
import { ErrorBoundary } from 'react-error-boundary';
import { UserPrefsProvider } from './context/UserPrefsProvider';

function App() {

	return (
		<>
			<UserPrefsProvider>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<ErrorBoundary
						fallback={
							<div>
								Hmmm, something's missing. Try refreshing your browswer, or
								contact support@meCapsules.com and we'll give you a hand.
							</div>
						}
					>
						<RouterProvider router={router} />
					</ErrorBoundary>
				</ThemeProvider>
			</UserPrefsProvider>
		</>
	);
}
export default App;
