import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, FormControl, TextField, Paper, Typography } from '@mui/material';
import ButtonMain from '../buttons/ButtonMain';
import { colorPack } from '../../theme/theme';
import { useFirestore } from '../../hooks/useFirestore';
import EggIcon from '@mui/icons-material/Egg';

const paperStyle = {
	marginBottom: 100,
	marginLeft: 'auto',
	marginRight: 'auto',
	padding: 8,
	width: '95%',
	backgroundColor: 'lightGrey',
};

export default function CreateCapsuleForm({ closeModal }) {
	const { primaryDark, primaryMain } = colorPack;
	const { createCapsule } = useFirestore();
	const [newCapsuleName, setNewCapsuleName] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			createCapsule(newCapsuleName);
			closeModal();
			navigate('/');
		} catch (error) {
			console.log('Error creating new capsule.', error);
		}
	};

	return (
		<Paper elevation={9} style={paperStyle}>
            <Typography sx={{mb:1}}>Capsule names cannot be changed once created.</Typography>
			<FormControl fullWidth>
				<TextField
					id="newCapsuleName"
					label="Capsule Name"
					sx={{ marginBottom: 1, backgroundColor: 'white' }}
					value={newCapsuleName}
					onChange={(e) => setNewCapsuleName(e.target.value)}
					variant="outlined"
				/>
			</FormControl>
			<Box
				sx={{
					display: 'flex',
					flexFlow: 'row noWrap',
					justifyContent: 'space-between',
				}}
			>
				<span>
					<ButtonMain
						width={'200px'}
						type="submit"
						onClick={handleSubmit}
						color={'white'}
						bgColor={primaryMain}
						label={'Create Capsule'}
						startIcon={<EggIcon style={{ marginBottom: 2 }} />}
					/>
				</span>
				<span>
					<ButtonMain
						onClick={closeModal}
						width={'50px'}
						type="submit"
						color={primaryDark}
						bgColor={'white'}
						label={'Cancel'}
					/>
				</span>
			</Box>
		</Paper>
	);
}
