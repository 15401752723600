import React from 'react';
import { Box, Stack } from '@mui/system';
import RightNavTrainingCard from './RightNavTrainingCard';
import { useMotion } from '../../hooks/useMotion';
import { motion } from 'framer-motion';
import NavBarCardSide from './NavBarCardSide';
import FabTrainingCard from './FabTrainingCard';


export default function TrainingTile() {
	const { delayFromRight } = useMotion();
    
	return (
		<Stack
			direction="column"
			justifyContent="space-between"
			alignItems="center"
			spacing={2}
		>
			<motion.div
				style={{ position: 'fixed', top: 50, right: 70 }}
				variants={delayFromRight}
				initial="hidden"
				animate="visible"
				exit="exit"
			>
				<Box sx={{ textAlign: 'right' }}>
					<NavBarCardSide
						label={'Folders keep Media organized'}
					/>
				</Box>
			</motion.div>
			<Box sx={{ textAlign: 'left', maxWidth: '60vw'}}>
				<FabTrainingCard
                    message={
						'Add Photos & Videos To Your Capsule Here'
					}
				/>
			</Box>

			<motion.div
				style={{ position: 'fixed', top: 118, right: 70 }}
				variants={delayFromRight}
				initial="hidden"
				animate="visible"
				exit="exit"
			>
				<Box sx={{ textAlign: 'right' }}>
					<NavBarCardSide
						label={'Turn On Editing Here To Delete Pictures & Videos'}
					/>
				</Box>
			</motion.div>

			<RightNavTrainingCard />
		</Stack>
	);
}
