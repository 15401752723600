export const imgCardData = {
	img1: {
		txt: "It's about transforming your digital storage from a vast, uncharted territory into a well-organized, easily navigable personal museum of your life.",
		url: 'images/loGlasses.png',
	},
	img2: {
		txt: "With meCapsules, your memories are your own. It's time for you to enjoy a sanctuary where special moments are stored away from the public eye, ensuring the only likes and follows that matter are your own. It's not just about privacy; it's about reclaiming thenarrative of your life from the social media algorithms designed tocommodify your memories.",
		url: 'images/viUmbrella.jpg',
	},
	img3: {
		txt: 'meCapsules redefines memory preservation by enabling users to capture and store a wide array of memories, from the visual to the auditory and the textual. By creating capsules that can include favorite quotes, audio memories, photos, and video messages, meCapsules ensures the full spectrum of your experiences is preserved. ',
		url: 'images/wubbaFairy.png',
	},
	img4: {
		txt: '	meCapsules offers a focused approach to saving memories. By creating dedicated capsules for your most treasured photos and videos, meCapsules ensures your special moments are always just a few clicks away.',
		url: 'images/wubbaFairy.png',
	},
	img5: {
		txt: "In our digital age, capturing moments goes beyond photos and videos. The ephemeral, like a child's laugh or a heartfelt conversation, often escapes our grasp because traditional platforms focus on visual memories. The longing to preserve more intangible aspects of our experiences—be it through favorite quotes, voice recordings, or video messages for future generations—remains unfulfilled.",
		url: 'images/viUmbrella.jpg',
	},
};
