import React from 'react';
import NoCapsuleCard from './NoCapsuleCard';
import { Stack } from '@mui/system';
import MenuBarTrainingTile from './MenuBarTrainingTile';
import { useMotion } from '../../hooks/useMotion';
import { motion } from 'framer-motion';

export default function TrainingTile() {
	const { delayFromRight } = useMotion();

	return (
		<Stack
			direction="column"
			justifyContent="space-between"
			alignItems="center"
			spacing={2}
		>
			<NoCapsuleCard />
			<motion.div
				style={{ position: 'fixed', bottom: 100 }}
				variants={delayFromRight}
				initial="hidden"
				animate="visible"
				exit="exit"
			>
				<MenuBarTrainingTile />
			</motion.div>
		</Stack>
	);
}
