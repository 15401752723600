import React from 'react';
import { Paper, Stack, Box } from '@mui/material';
import FavouritesIcon from '../buttons/FavouritesIcon';
import useFavourite from '../../hooks/useFavourite';
import QuoteComp from './QuoteComp';

export default function QuoteCard({
        id,
        favourite,
        capsuleName,
        quote,
        context,
        author,
        date,
    }){

    const {toggleAllFavourites, toggleQuoteStatus} = useFavourite(); 
    const cardType = 'quoteCard';
    
    const handleToggleFavourites = async () => {
            const options = {quote, context, author, date, capsuleName, id};
            try {
                    await toggleAllFavourites(options, favourite, cardType);
                    await toggleQuoteStatus(capsuleName, favourite, id);
            } catch (error) {
                    console.log("Error toggling favourites.  Error: ", error);        
            }
    }

    return (
        <Paper elevation={3} sx={{p:1}}>
            <Stack gap='2'>
                <Box sx={{position: 'relative', ml: 'auto'}}>
                    <FavouritesIcon 
                            favourite={favourite}
                            onClick={handleToggleFavourites}
                    />
                </Box>
                    <QuoteComp
                        quote={quote} 
                        context={context} 
                        author={author} 
                        date={date}                
                    />
                </Stack>
        </Paper>  
    )
}
