import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

const inputField = {
      display: 'flex',
      alignItems: 'stretch',
      backgroundColor: 'white',
      borderRadius: 4,
}

export default function FormInputText({ name, control, label }) {
  const inputRef = useRef(); 
    
  return ( 
    <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange }, fieldState: { error } }) => (
            <TextField
                id={name}
                style={inputField}
                label={label}
                onChange={onChange}
                value={inputRef.current}
                helpertext={error ? error.message : null}
                error={!!error}
                margin='none'
            />
        
        )}
    />
  );
}
