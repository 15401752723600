import { useContext, useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import BottomNav from "./BottomNav";
import LoginPage from "../../pages/LoginPage";
import { AuthContext } from '../../context/AuthProvider';
import CapsuleHeaderNav from './CapsuleHeaderNav';
import { useAuth } from '../../hooks/useAuth';

const Root = () => {
    const { isLoggedIn, login } = useContext(AuthContext);
    const { validateToken } = useAuth();
    const location = useLocation(); 
    const navigate = useNavigate();

    const validateSession = () => {
      let isSession = validateToken();
      if(isSession){
            login();
      } else {
            navigate('/login');
      }
  }
  
  useEffect(() => {
        validateSession();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isLoggedIn]);

  useEffect(() => {
    isLoggedIn && login(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isLoggedIn]);
  
    return (
        (isLoggedIn) ? 
            <>
                <Header />
                <div style={{marginTop: 56}}>
                    <Outlet />
                    {(location?.pathname?.length > 1 && location?.pathname !== '/favourites') && <CapsuleHeaderNav />}
                </div>
                <div style={{paddingTop: 40}}>
                </div>
                
                <div style={{paddingTop: 64}}>
                    <BottomNav />
                </div>
            </> 
        : 
            <LoginPage /> 
    );
};

export default Root;