import heic2any from 'heic2any';

// Utility function for HEIC to JPG conversion
const convertHeicToJpg = async (file) => {
  try {
    const convertedFile = await heic2any({
      blob: file,
      toType: 'image/jpeg',
    });
    // Assign a new name with the .jpg extension
    convertedFile.name = file.name.substring(0, file.name.lastIndexOf('.')) + '.jpg';
    return convertedFile;
  } catch (error) {
    console.error('Error converting HEIC file:', error);
    throw new Error('Failed to convert HEIC file.');
  }
};

// Check if the file extension is HEIC
const isHeicFile = (filename) => {
  const extension = filename.split('.').pop().toLowerCase();
  return ['heic', 'heif'].includes(extension);
};

const useSingleMediaConverter = () => {
  /**
   * This function takes a single file input, checks if it's a HEIC file, and converts it to JPG if necessary.
   * It supports JPG, PNG, and HEIC file types.
   * @param {File} file - The single image file selected by the user.
   * @returns A Promise that resolves to either the original file or a converted JPG file.
   */
  const convertFileIfNeeded = async (file) => {
    if (!file) return null; // No file was provided

    const filename = file.name;
    if (isHeicFile(filename)) {
      // Convert HEIC to JPG
      try {
        return await convertHeicToJpg(file);
      } catch (error) {
        console.error('Error converting file:', error);
        throw new Error('File conversion failed.');
      }
    } else {
      // Return the original file if it's not HEIC
      return file;
    }
  };

  return {
    convertFileIfNeeded,
  };
};

export default useSingleMediaConverter;
