import React, { useState, useEffect, useContext } from 'react';
import CapsulesTile from '../components/tiles/CapsulesTile';
import { useFirestore } from '../hooks/useFirestore';
import { Paper } from '@mui/material';
import TrainingTile from '../components/Training/TrainingTile';
import { useAsync } from '../hooks/useAsync';
import { UserPrefsContext } from '../context/UserPrefsProvider.js';

const paperStyle = {
	display: 'flex',
	flexFlow: 'column noWrap',
	alignContent: 'stretch',
	justifyContent: 'center',
	alignItems: 'stretch',
	marginBottom: 100,
	padding: 8,
};

export default function DashboardPage() {
	const { newUserTrainingVisible } = useContext(UserPrefsContext);
	const [capsules, setCapsules] = useState(undefined);
	const { buildUser, userCapsules, fetchUserCapsules } = useFirestore();
	const [showTraining, setShowTraining] = useState(false);
	const [asyncState, setAsyncState] = useState('');
	const [showLoader, setShowLoader] = useState(false);
	const loadingState = useAsync(asyncState);

    
	useEffect(() => {
        buildUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
	useEffect(() => {
        fetchUserCapsules();
	}, [fetchUserCapsules]);
    
    useEffect(() => {
        newUserTrainingVisible && setShowTraining(true);
        !newUserTrainingVisible && setShowTraining(false);
    }, [newUserTrainingVisible]);

	useEffect(() => {
		if (userCapsules === null || userCapsules?.length === undefined) {
			setAsyncState('pending');
			setShowLoader(true);
		}
		if (userCapsules?.length === 0) {
			setAsyncState('success');
			setShowLoader(false);
		}
		if (userCapsules !== null && userCapsules?.length > 0) {
			setAsyncState('success');
			setShowLoader(false);
			setCapsules(userCapsules);
		}
	}, [userCapsules, fetchUserCapsules]);

	return (
		<>
			{showLoader && loadingState}
			{showTraining && userCapsules?.length === 0 && <TrainingTile />}
			{capsules?.length > 0 && (
				<Paper elevation={0} style={paperStyle}>
					<CapsulesTile capsules={capsules} />
				</Paper>
			)}
		</>
	);
}
