import { useEffect, useState } from 'react';
import { db } from '../services/firebase';
import { getAuth } from 'firebase/auth';
import { doc, deleteDoc } from 'firebase/firestore';

export const useDelete = () => {
	const auth = getAuth();
	const [uid, setUid] = useState('');

	async function deleteCapsule(capsuleToDelete) {
		!uid ?? console.log('No UID Found. Unable to delete Capsule');
		!capsuleToDelete ?? console.log('No capsule to delete name found.');
		capsuleToDelete === undefined ??
			console.log('Capsule to delete is undefined.');
		try {
			const path = `users/${uid}/capsules/${capsuleToDelete}`;
			console.log('Path is: ', path);
			await deleteDoc(doc(db, path))
				.then(console.log(capsuleToDelete, ' was successfully deleted'))
				.catch((e) => console.log('error: ', e));
		} catch (error) {
			console.log('Error creating new user.  Error: ', error);
		}
	}

	useEffect(() => {
		const userUid = auth.currentUser.uid; //validates currentUser without Auth Context to be CERTAIN before deleting a capsule
		setUid(userUid);
	}, [auth.currentUser.uid]);

	return {
		deleteCapsule,
	};
};

export default useDelete;
