import React from 'react'

const iconStyle = {
  maxWidth: 24,
  maxHeight: 24,
}

export default function NavNewCapsuleIcon({onClick}) {
  return (
    <img onClick={onClick} src="\images\navNew.svg" style={iconStyle} alt='capsules' type='button' />
  )
}
