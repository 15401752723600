import React from 'react';
import { Typography, Box } from '@mui/material';

export default function HeaderCard() {
	return (
		<Typography
			variant="h5"
			component="h5"
			style={{ fontStyle: 'italic', color: 'white' }}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					p: 1,
					mt: 4,
					mb: 1,
				}}
			>
				<img
					src="\images\meCapsulesHeader.png"
					alt="me capsules"
					style={{
						width: '90vw',
						maxWidth: 375,
					}}
				/>
			</Box>
			<Box sx={{ maxWidth: 375, mt: -2.5, mb: 6, pr: 0.5 }}>
				<Typography variant="subtitle1" textAlign="right">
					If it matters to you, put it in a capsule.
				</Typography>
			</Box>
			<Box>
				<Typography
					variant="subtitle1"
					color="primaryDark"
					sx={{ textAlign: 'center', p: 1, mb: 4 }}
					component="p"
				>
					Step into a realm where memories are not commodities for public
					validation but treasures for personal reflection.
				</Typography>
			</Box>
		</Typography>
	);
}
