import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Paper } from '@mui/material';

const paperStyle = {
	display: 'flex',
	flexFlow: 'column noWrap',
	justifyContent: 'center',
	alignItems: 'center',
	padding: 6,
	width: 100,
	minHeight: 100,
	background: `linear-gradient(0deg, rgba(122,0,64,0.80) 0%, rgba(161,3,85,0.80) 20%, rgba(214,77,148,0.80) 100%)`,
	color: 'primaryDark',
	border: '1px solid grey',
};

export default function MediaFolderNavBtn({ capsuleName, subCapsuleName }) {
	const linkRoute = `/${capsuleName}/Media/${subCapsuleName}`;

	return (
		<Link to={linkRoute} style={{ textDecoration: 'none' }}>
			<Paper elevation={6} style={paperStyle}>
				<Typography
					style={{ textTransform: 'capitalize', color: 'white' }}
					variant="context"
					component="span"
					sx={{textAlign:'center'}}
				>
					{subCapsuleName}
				</Typography>
			</Paper>
		</Link>
	);
}
