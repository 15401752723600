import React from 'react';
import { Typography, Paper } from '@mui/material';
import { Stack } from '@mui/system';
import { colorPack } from '../../theme/theme';
import { motion } from 'framer-motion';
import { useMotion } from '../../hooks/useMotion';
import SouthIcon from '@mui/icons-material/South';

export default function NavBarCardDown({label, color}) {
    const { primaryMain, secondaryDark } = colorPack;
    const { zoomIn, delayFromBottom } = useMotion();

    return (
            <motion.div
                variants={zoomIn}
                initial='hidden'
                animate='visible' 
                exit='exit'
                id="motion div here"
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignContent="center"
                    spacing={0}
                >
                    <Paper elevation={0} style={{padding: 4}}>
                    <Typography
                            style={{fontSize: '1rem'}} 
                            component='span'
                            color={() => ( color ? color : primaryMain)}
                        >
                            {label} 
                        </Typography>
                    </Paper>
                    <motion.div
                        variants={delayFromBottom}
                        initial='hidden'
                        animate='visible' 
                        exit='exit'
                    >
                  <SouthIcon 
                            fontSize='large' 
                            style={{color: secondaryDark, position: 'fixed', bottom: -50}} 
                        />
                    </motion.div>
                </Stack>
            </motion.div>
    )
}

