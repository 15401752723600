import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { colorPack } from '../../theme/theme';
import { motion } from 'framer-motion';
import { useMotion } from '../../hooks/useMotion';
import { formatName, getLinkRoute } from '../../utils/utils';
import MsgModal from '../modals/MsgModal';
import EditCapsuleMenu from '../menus/EditCapsuleMenu';

const { primaryMain } = colorPack;

const capsuleBtn = {
	position: 'relative',
	height: 80,
	width: '45%',
	margin: 'auto',
	bottom: -60,
	padding: 8,
	overflow: 'hidden',
};

const labelStyle = {
	margin: 0,
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: `translate(-50%, -50%)`,
	lineHeight: 1.35,
	padding: 8,
};

const iconStyle = {
	margin: 0,
	position: 'absolute',
	top: '90%',
	left: '94%',
	transform: `translate(-50%, -50%)`,
	color: primaryMain,
};

export default function CapsuleButton({
	capsuleName,
	subCapsuleName,
	coverImg,
}) {
	const [showMsgModal, setShowMsgModal] = useState(false);
	const { zoomIn } = useMotion();
	const coverImage = coverImg?.url;

	const coverPhoto = {
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundImage: `url(${coverImage})`,
		backgroundRepeat: 'no-repeat',
		overflow: 'hidden',
		height: 220,
		width: 380,
		maxWidth: '98vw',
		minWidth: 320,
	};

	const handleCloseMsgModal = () => {
		setShowMsgModal(false);
	};

	return (
		<motion.div
			style={{ padding: 0 }}
			variants={zoomIn}
			initial="hidden"
			animate="visible"
			exit="exit"
		>
			<Paper elevation={9} style={coverPhoto}>
				<Link
					to={getLinkRoute(capsuleName, subCapsuleName)}
					style={{ textDecoration: 'none' }}
				>
					<motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
						<Paper sx={{opacity:'80%'}} style={capsuleBtn} elevation={9}>
							<Typography sx={{textAlign:'center'}} variant="h6" style={labelStyle} component={'p'}>
								{formatName(capsuleName)}
							</Typography>
						</Paper>
					</motion.div>
				</Link>
			</Paper>

			<div onClick={() => setShowMsgModal(true)}>
				<EditIcon style={iconStyle} />
			</div>

			{showMsgModal && (
				<MsgModal
					userMsg={
						<EditCapsuleMenu
							closeModal={handleCloseMsgModal}
							capsuleName={capsuleName}
						/>
					}
					closeModal={handleCloseMsgModal}
					isOpen={true}
				/>
			)}
		</motion.div>
	);
}
