import React, { useState } from 'react';
import ImageCard from '../cards/ImageCard';
import { Paper, Stack } from '@mui/material';
import VideoCard from '../cards/VideoCard';
import UploadMediaFormModal from '../forms/UploadMediaFormModal';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { colorPack } from '../../theme/theme';

const mediaBoxStyle = {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
};

export default function MediaTile({ mediaArr }) {
    const [showDeleteBtn, setShowDeleteBtn] = useState(false);
    const [showUploadMediaForm, setShowUploadMediaForm] = useState(false);
    const { secondaryDark } = colorPack;

    const handleShowDelete = () => {
        setShowDeleteBtn(!showDeleteBtn);
    };

    const handleHideUploadMediaForm = () => {
        setShowUploadMediaForm(false);
    };

    return (
        <>
            <Stack direction='column' gap={2} sx={{ minWidth: '100%' }}>
                {/* Wrap the icon in a div with flex display to align it to the right */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <ModeEditOutlineRoundedIcon
                        onClick={handleShowDelete}
                        style={{ color: secondaryDark }}
                    />
                </div>
                <div style={mediaBoxStyle}>
                    {showUploadMediaForm &&
                        <UploadMediaFormModal isOpen={showUploadMediaForm} closeModal={handleHideUploadMediaForm} />
                    }
                </div>
                {mediaArr?.length > 0 &&
                    <Paper elevation={0} style={{ padding: 0 }}>
                        {mediaArr?.map(obj =>
                            obj?.url?.toLowerCase().includes('.mp4') ||
                            obj?.url?.toLowerCase().includes('.mov') ?
                                <VideoCard
                                    key={obj.url}
                                    url={obj.url}
                                    type='video/*'
                                    showDeleteBtn={showDeleteBtn}
                                    favourite={obj.favourite}
                                    capsuleName={obj.capsuleName}
                                    subCapsuleName={obj.subCapsuleName}
                                    fileName={obj.fileName}
                                />
                                :
                                <ImageCard
                                    key={obj.url}
                                    url={obj.url}
                                    type='image/*'
                                    showDeleteBtn={showDeleteBtn}
                                    favourite={obj.favourite}
                                    capsuleName={obj.capsuleName}
                                    subCapsuleName={obj.subCapsuleName}
                                    fileName={obj.fileName}
                                />
                        )}
                    </Paper>
                }
            </Stack>
        </>
    );
}
