import { useCallback, useState, useContext } from 'react';
import { db } from '../services/firebase';
import {
	query,
	setDoc,
	doc,
	collection,
	getDocs,
	getDoc,
	onSnapshot,
} from 'firebase/firestore';
import { AuthContext } from '../context/AuthProvider';

/**
 * @param {Object} db - The firebase/app#FirebaseApp instance with which the returned Firestore instance is associated
 * @param {?string} uid - The Google user id of the signed in user if user is signed in
 *
 */

export const useFirestore = () => {
	const authContext = useContext(AuthContext);
	const uid = authContext.uid;
	const user = authContext.user;
	const [userCapsules, setUserCapsules] = useState(null);
	const [userSubCapsules, setUserSubCapsules] = useState([]);

	/**
	 * will check if authenticated user has an existing account and create new user
	 * with default 'allFavourites' capsules if no existing account found
	 */
	async function buildUser() {
		if (!uid) {
			return;
		} else {
			try {
				const snap = await getDoc(doc(db, 'users', uid));
				if (snap.exists()) {
					console.log('Existing user Found.');
					return;
				} else {
					setDoc(doc(db, 'users', uid), {
						displayName: user?.displayName,
						email: user?.email,
						uid: uid,
						newUserTrainingVisible: true,
					})
						.then(() => createCapsule('allFavourites'))
						.catch((error) =>
							console.log('Error creating favourites folder.  Error:  ', error)
						);
				}
			} catch (error) {
				console.log('Error creating new user.  Error: ', error);
			}
		}
	}

	/**
	 * will create a new user capsule
	 * @param {string} newCapsuleName - name of the new capsule
	 */
	const createCapsule = (newCapsuleName) => {
		//!! important, stops empty media arr from being added to allFavourites when new acct is created
		let options = {
			uid: uid,
			capsuleName: newCapsuleName,
		};
		if (newCapsuleName !== 'allFavourites') {
			options = { ...options, media: [] };
		}
		setDoc(doc(db, `users/${uid}/capsules/`, newCapsuleName), options).catch(
			(error) => console.log('Error creating new capsule.  Error: ', error)
		);
	};

	/**
	 * will create a new user subCapsule
	 * @param {string} capsuleName - name of the capsule within which the subCapsule will live
	 * @param {string} newCapsuleName - name of the new subCapsule
	 */
	const createSubCapsule = ({ newCapsuleName, capsuleName }) => {
		setDoc(
			doc(
				db,
				`users/${uid}/capsules/${capsuleName}/subCapsules`,
				newCapsuleName
			),
			{
				uid: uid,
				subCapsuleName: newCapsuleName,
			}
		).catch((error) =>
			console.log('Error creating new capsule.  Error: ', error)
		);
	};

	/**
	 * will fetch the user's capsules
	 * @returns the user's capsules (user's root documents)
	 */
	async function getAllCapsules() {
		const capsuleRef = collection(db, `users/${uid}/capsules/`);
		return getDocs(capsuleRef);
	}

	/**
	 * fetches the user-named sub-collections (eg. media folders, items, quotes, journal) associated with the selected user capsule
	 * @param {string} capsuleName - name of the target document
	 * @returns the user's sub-collections
	 */
	const getAllSubCapsules = (capsuleName) => {
		const subCapsuleRef = collection(
			db,
			`users/${uid}/capsules/${capsuleName}/subCapsules`
		);
		return getDocs(subCapsuleRef);
	};

	/**
	 * will fetch the user's capsules wrapped in useCallback hook
	 * @returns an Array containing the user's capsules (consumed by the dashboardPage) | null if no user capsules exist
	 */
	const fetchUserCapsules = useCallback(() => {
		(function getCapsules() {
			if (uid?.length > 0) {
				getAllCapsules()
					.then((capsules) =>
						capsules.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
					)
					.then((data) =>
						data.filter(
							(item) =>
								item?.capsuleName !== undefined &&
								item?.capsuleName !== 'allFavourites'
						)
					)
					.then((result) => sortUserCapsules(result))
					.catch((error) =>
						console.log('Unable to retrieve user capsules.  Error: ', error)
					);
				const qSub = query(collection(db, `users/${uid}/capsules`));
				onSnapshot(qSub, (snapData) => {
					const snap = snapData?.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
					}));
					const snapResult = snap.filter(
						(item) =>
							item?.capsuleName !== undefined &&
							item?.capsuleName !== 'allFavourites'
					);
					sortUserCapsules(snapResult);
					setUserCapsules(snapResult);
				});
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uid, uid.length]);

	/**
	 * will sort user's capsules alphabetically and set userCapsules to Null | capsules  if array length > 0
	 * @param {Array} capsuleArray - Array of user's capsule names
	 * @returns an alphabetically sorted array of the user's capsule |null
	 */
	function sortUserCapsules(capsuleArray) {
		if (capsuleArray?.length === 0) {
			return setUserCapsules([]);
		} else {
			const sortedCapsules = capsuleArray?.sort((a, b) =>
				a?.capsuleName?.localeCompare(b?.capsuleName)
			);
			return setUserCapsules(sortedCapsules);
		}
	}

	/**
	 * will fetch the user's sub-capsules and set userSubcapsules to be consumed by user's capsules
	 * @param {string} capsuleName - name of the target document (capsule where subCapsules live)
	 * @returns a Promise that resolves with an Array of all the user's subCapsules
	 */
	const fetchUserSubCapsules = useCallback(
		(capsuleName) => {
			(function getSubCapsules() {
				getAllSubCapsules(capsuleName)
					.then((subCapsules) =>
						subCapsules.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
					)
					.then((subCapsuleData) => setUserSubCapsules(subCapsuleData))
					.catch((error) =>
						console.log('Unable to retrieve user subCapsules.  Error: ', error)
					);
			})();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[uid]
	);

	/**
	 * will subscribe to changes in the user's subCapsule collection and provide real-time updates (used to display new folders without refreshing or reloading the page)
	 * @param {string} capsuleName name of the target capsule
	 * @returns an unsubscribe function so the event listen can be subscribed/unsubscribed in a useEffect hook
	 */
	const subCapsuleListener = (capsuleName) => {
		const qSub = query(
			collection(db, `users/${uid}/capsules/${capsuleName}/subCapsules`)
		);
		const unsubscribe = onSnapshot(qSub, (snapData) => {
			try {
				const subCapsuleData = snapData?.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
				}));
				setUserSubCapsules(subCapsuleData);
				return unsubscribe;
			} catch (error) {
				console.log('subCapsule eventListener error. Error: ', error);
			}
		});
		return unsubscribe;
	};

	return {
		subCapsuleListener,
		buildUser,
		createCapsule,
		createSubCapsule,
		fetchUserCapsules,
		fetchUserSubCapsules,
		userSubCapsules,
		userCapsules,
	};
};
