import React from 'react';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
} from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import Root from './components/nav/Root';
import DashboardPage from './pages/DashboardPage';
import CapsulePage from './pages/CapsulePage';
import MediaPage from './pages/MediaPage';
import QuotesPage from './pages/QuotesPage';
import ItemPage from './pages/ItemPage';
import JournalPage from './pages/JournalPage';
import MediaIndexPage from './pages/MediaIndexPage';
import MediaFolderPage from './pages/MediaFolderPage';
import FavouritesPage from './pages/FavouritesPage';
import PrivacyPage from './pages/PrivacyPage';

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
				<Route path="privacy" element={<PrivacyPage />} />
			<Route element={<Root />} path="/" errorElement={<ErrorPage />}>
				<Route path="/favourites" element={<FavouritesPage />} />
				<Route index element={<DashboardPage />} />
				<Route path=":capsuleName" element={<CapsulePage />}>
					<Route path="media" element={<MediaPage />}>
						<Route index={true} element={<MediaIndexPage />} />
						<Route
							index={false}
							path=":subCapsuleName"
							element={<MediaFolderPage />}
						></Route>
						<Route path="quotes" element={<QuotesPage />} />
						<Route path="items" element={<ItemPage />} />
						<Route path="journal" element={<JournalPage />} />
					</Route>
					<Route path="quotes" element={<QuotesPage />} />
					<Route path="items" element={<ItemPage />} />
					<Route path="journal" element={<JournalPage />} />
				</Route>
				<Route path="*" element={'Hmmm, nothing to see here.'} />
			</Route>
		</>
	)
);

export default router;
