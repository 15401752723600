import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';

export default function PrivacyPage() {
	const navigate = useNavigate();

	const handleClickHome = () => {
		navigate('/');
	};

	return (
		<Box sx={{p:2}}>
            <Typography variant="body1" component="div">
                
                <Typography variant="h3" component="div">
                    Privacy Policy
                </Typography>
                    <Box sx={{p:1.5}}>
                                    <Typography sx={{mb:1}} variant="h5" component="div">
                                        Effective Date: January 1, 2024
                                    </Typography>
                                        At meCapsules, we are committed to ensuring the privacy and security
                                        of your personal information. This Privacy Policy outlines our
                                        practices and procedures regarding the collection, use, and disclosure
                                        of user data when you use our website, www.mecapsules.com (“Website”)
                                        and associated services (“Services”). By accessing or using our
                                        Website and Services, you consent to the practices described in this
                                        Privacy Policy. Please carefully read the following information to
                                        understand our practices regarding your personal information.
                        <Box sx={{p:1}}>
                                        <Typography sx={{mt:1, mb:1}} variant="h6" component="div">
                                            1. Collection of User Data
                                        </Typography>
                                        We collect and store certain personal information when you visit our
                                        website or use our Services. This information may include, but is not
                                        limited to: a. Personal information provided by you: - When you create
                                        an account, we may collect your name, email address, and a password. -
                                        When you contact us for support or other inquiries, we may collect
                                        your name, email address, and any information you voluntarily provide
                                        to us. b. Automatically collected information: - When you access and
                                        use our Website, we may automatically collect certain information,
                                        including your IP address, browser type, device type, operating
                                        system, referring URLs, and usage information.
                                        <Typography sx={{mt:1, mb:1}} variant="h6" component="div">
2. Use of User Data
                                        </Typography>
                                        We may use the collected personal information to: - Provide and
                                        improve our Services. - Send you important information, updates, and
                                        announcements regarding our Services. - Respond to your inquiries,
                                        requests, or feedback. - Conduct research and analysis to improve our
                                        Website and Services.
                                        <Typography sx={{mt:1, mb:1}} variant="h6" component="div">

                                            3. Sharing of User Data
                                        </Typography>
                                        We do not sell or share your personal information with third parties
                                        for marketing purposes. We may share your personal information with
                                        trusted third parties in the following circumstances: a. Service
                                        Providers: We may disclose your personal information to third-party
                                        service providers who assist us in providing and maintaining our
                                        Services. These service providers are obligated to maintain the same
                                        level of confidentiality and security for your personal information.
                                        b. Legal Requirements: We may disclose your personal information as
                                        required by applicable laws, regulations, legal processes, or
                                        government requests.
                                        <Typography sx={{mt:1, mb:1}} variant="h6" component="div">

                                            4. Data Security
                                        </Typography>
                                        We implement security measures to protect your personal information
                                        against unauthorized access, alteration, disclosure, or destruction.
                                        However, please note that no method of transmission over the internet
                                        or electronic storage is 100% secure; thus, we cannot guarantee
                                        absolute security.
                                                            <Typography sx={{mt:1, mb:1}} variant="h6" component="div">

                                            5. Data Retention
                                        </Typography>
                                        We will retain your personal information for as long as necessary to
                                        fulfill the purposes outlined in this Privacy Policy, unless a longer
                                        retention period is required by law.
                                                            <Typography sx={{mt:1, mb:1}} variant="h6" component="div">

                                            6. Third-Party Websites
                                        </Typography>
                                        Our Website and Services may contain links to third-party websites or
                                        services. This Privacy Policy does not apply to any third-party
                                        websites or services. We encourage you to review the privacy policies
                                        of these third parties before providing them with your personal
                                        information.
                                                            <Typography sx={{mt:1, mb:1}} variant="h6" component="div">

                                            7. Children's Privacy
                                        </Typography>
                                        Our Website and Services are not intended for use by individuals under
                                        the age of 13. We do not knowingly collect personal information from
                                        children. If you believe we have inadvertently collected personal
                                        information from a child, please contact us immediately, and we will
                                        promptly remove the information from our records.
                                                            <Typography sx={{mt:1, mb:1}} variant="h6" component="div">

                                            8. Updates to this Privacy Policy
                                        </Typography>
                                        We reserve the right to update or modify this Privacy Policy at any
                                        time. Any changes we make will be posted on this page with an updated
                                        effective date. We encourage you to periodically review this Privacy
                                        Policy for the latest information about our privacy practices.
                                        <Typography sx={{mt:1, mb:1}} variant="h6" component="div">

                                            9. Contact Us
                                        </Typography>
                                        If you have any questions, concerns, or requests regarding this
                                        Privacy Policy or the privacy practices of meCapsules, please contact
                                        us at [contact email]. By using our Website and Services, you hereby
                                        acknowledge and agree to the terms and conditions of this Privacy
                                        Policy.
                                </Box>
                              
                                <Button>
                                    <Typography onClick={handleClickHome} variant='h6' component='div'>
                                        Return Home
                                    </Typography>
                                </Button>
                </Box>
        </Typography>
        </Box>
    
    
		
	);
}
