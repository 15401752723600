import React from 'react'

const iconStyle = {
  maxWidth: 24,
  maxHeight: 24,
}

export default function NavFireList({onClick}) {
  return (
    <img onClick={onClick} src="\images\navFireList.svg" style={iconStyle} alt='fire list' type='button' />
  )
}
