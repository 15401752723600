import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import { useMedia } from '../hooks/useMedia';
import { useParams, useLocation } from 'react-router-dom';
import MediaFolderTile from '../components/tiles/MediaFolderTile';

export default function MediaFolderPage() {
  const [ subMediaArr, setSubMediaArr ] = useState([]);
  const { fetchSubCapsuleMedia, subCapsuleMedia } = useMedia(); 
  const { capsuleName, subCapsuleName } = useParams();
  
  const location = useLocation(); 
  
  // !! required to force reload of data in React-Router v6 since mounted component does not change (only the data)  
  // !! also ensures no media is displayed when user selects a an empty subCollection (e.g. right after creating a new media folder)   
  useEffect(() => {
    fetchSubCapsuleMedia(capsuleName, subCapsuleName); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location]);

  useEffect(() => {
      setSubMediaArr(subCapsuleMedia);
  },[subCapsuleMedia]);

    return (
      <Paper sx={{display:'flex', flexFlow:'row wrap', justifyContent:'center',alignItems:'center'}} elevation={3}>
          <MediaFolderTile
              subMediaArr={subMediaArr}
            /> 
      </Paper>
    )
  } 
    