import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Box, Paper, Stack, Typography } from '@mui/material';
import FormInputText from "./formComponents/FormInputText";
import FormInputTextArea from './formComponents/FormInputTextArea';
import MediaFilePicker from './MediaFilePicker';
import { colorPack } from '../../theme/theme';
import ButtonMain from '../buttons/ButtonMain';
import PreviewImgCard from '../cards/PreviewImgCard';
import ErrorCard from '../cards/ErrorCard';
import { useItem } from '../../hooks/useItem';
import { motion } from 'framer-motion';
import { useMotion } from '../../hooks/useMotion';
import useSingleMediaConverter from '../../hooks/useSingleMediaConverter'; // Import the new hook


//TO DO:  Add videos as special items. 


export default function UploadItemForm({ closeModal }) {
    const { secondaryMain, primaryLight, primaryMain } = colorPack;
    const [previewImg, setPreviewImg] = useState(null);
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const { addNewItem, itemPreviewProgress } = useItem();
    const { zoomIn } = useMotion();
    const { convertFileIfNeeded } = useSingleMediaConverter(); // Use the hook

    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            title: '',
            aboutItem: '',
            media: '',
        }
    });

    const onSubmit = async (formData) => {
        if (formData.media) {
            try {
                const convertedFile = await convertFileIfNeeded(formData.media);
                if (!convertedFile) {
                    setShowError(true);
                    return;
                }
                // Update formData with the converted file or original file
                formData.media = convertedFile;
                addNewItem(formData).then(() => {
                    setShowSuccess(true);
                    setTimeout(closeModal, 1500);
                });
            } catch (error) {
                console.log('Error handling the file:', error);
                setShowError(true);
            }
        }
    };

    // Function to handle file selection and conversion
    const handleFileChange = async (e) => {
        const file = e.target.files[0]; // Assuming single file selection
        if (file) {
            try {
                const convertedFile = await convertFileIfNeeded(file);
                // Update form data with the converted or original file
                setValue('media', convertedFile);
                // For preview, create a URL for the selected (or converted) file
                const fileUrl = URL.createObjectURL(convertedFile);
                setPreviewImg(fileUrl);
            } catch (error) {
                console.log('Error processing file:', error);
                setShowError(true);
            }
        }
    };

    useEffect(() => {
        if (itemPreviewProgress === 100) {
            setShowSuccess(true);
        }
    }, [itemPreviewProgress]);

    return (
        <>
            {showSuccess && <Typography variant='h5'>Success</Typography>}
            {showError &&
                <ErrorCard
                    message={'Invalid file type. Please select a jpg, png, or heic.'}
                    onClick={() => setShowError(false)}
                />
            }
            <motion.div
                style={{ padding: 0 }}
                variants={zoomIn}
                initial='hidden'
                animate='visible'
                exit='exit'
            >
                <Paper elevation={0} style={{ backgroundColor: primaryLight, padding: 8 }}>
                    <Stack gap={2}>
                        <FormInputText
                            label={"Item Name"}
                            name={'title'}
                            control={control}
                        />
                        <MediaFilePicker
                            name={"media"}
                            control={control}
                            onChange={handleFileChange} // Adjust to use new file handling logic
                        />
                        <FormInputTextArea
                            label={"Item Description"}
                            name={'aboutItem'}
                            control={control}
                        />
                        {previewImg && <PreviewImgCard src={previewImg} />}
                    </Stack>
                    <Box
                        style={{
                            display: 'flex',
                            flexFlow: 'row noWrap',
                            justifyContent: 'space-between',
                            marginTop: 16
                        }}
                    >
                        <ButtonMain
                            type='submit'
                            label={'Submit'}
                            onClick={handleSubmit(onSubmit)}
                            bgColor={secondaryMain}
                            color='white'
                        />
                        <ButtonMain
                            label={"Cancel"}
                            onClick={closeModal}
                            bgColor={primaryMain}
                            color={'white'}
                        />
                    </Box>
                </Paper>
            </motion.div>
        </>
    );
}
