import { useContext } from 'react';
import { UserPrefsContext } from '../context/UserPrefsProvider';
import { db } from '../services/firebase';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { AuthContext } from '../context/AuthProvider';

export const useSettings = () => {
	const { newUserTrainingVisible } = useContext(UserPrefsContext);
	const authContext = useContext(AuthContext);
	const uid = authContext.uid;
	console.log('isTrainingVisible from CONTEXT: ', newUserTrainingVisible);
	/**
	 * Fetches the current state of newUserTrainingVisible for the user.
	 * @returns {Promise<boolean>} A promise that resolves to the value of newUserTrainingVisible, or false if not set.
	 */
	const fetchNewUserTrainingVisible = async () => {
		if (!uid) return false; // Return false or a default value if no UID is present
		const userDocRef = doc(db, 'users', uid);
		try {
			const userDocSnap = await getDoc(userDocRef);
			if (userDocSnap.exists()) {
				const userData = userDocSnap.data();
				if (userData?.newUserTrainingVisible === undefined) {
					try {
						const userDocSnap = await getDoc(userDocRef);
						if (userDocSnap.exists()) {
							await setDoc(
								userDocRef,
								{ newUserTrainingVisible: true },
								{ merge: true }
							);
						} else {
							console.log('User document does not exist.');
						}
					} catch (error) {
						console.error('Error fetching newUserTrainingVisible:', error);
					}
				}
				// Return the value of newUserTrainingVisible, defaulting to false if it's not set
				return userData.newUserTrainingVisible ?? true;
			} else {
				// User document does not exist, so training is not visible
				return false;
			}
		} catch (error) {
			console.error('Error fetching newUserTrainingVisible state:', error);
			return false; // Return false or a default value in case of an error
		}
	};

	/**
	 * Toggles the newUserTrainingVisible field for the user in Firestore.
	 * If the field does not exist, it will be added with a value of true.
	 */
	const toggleTrainingVisible = async () => {
		if (!uid) return; // Ensure there's a user ID to work with
		const userDocRef = doc(db, 'users', uid);
		try {
			const userDocSnap = await getDoc(userDocRef);
			if (userDocSnap.exists()) {
				const userData = userDocSnap.data();
				// If newUserTrainingVisible does not exist or is false, set it to true
				// Otherwise, toggle its value
				const newUserTrainingVisible =
					userData.newUserTrainingVisible === undefined
						? true
						: !userData.newUserTrainingVisible;
				await setDoc(userDocRef, { newUserTrainingVisible }, { merge: true });
			} else {
				console.log('User document does not exist.');
			}
		} catch (error) {
			console.error('Error toggling newUserTrainingVisible:', error);
		}
	};

	return {
		fetchNewUserTrainingVisible,
		toggleTrainingVisible,
	};
};
