import React from 'react';
import { Box } from '@mui/material';
import ButtonMain from './ButtonMain';

export default function ContinueWithGoogle({ onClick }) {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<ButtonMain
				onClick={onClick}
				variant="secondary"
				width={250}
				bgColor={'white'}
				color={'blue'}
				label={'Continue with Google'}
			/>
		</Box>
	);
}
