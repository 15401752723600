import React from 'react';
import CapsuleButton from '../buttons/CapsuleButton';
import { Paper } from '@mui/material';

const paper = {
	display: 'flex',
	flexFlow: 'column noWrap',
	justifyContent: 'center',
	gap: 8,
};
const row = {
	display: 'flex',
	flexFlow: 'row wrap',
	width: '100%',
	justifyContent: 'center',
	margin: 'auto',
	gap: 8,
};

export default function CapsulesTile({ capsules }) {
	return (
		<Paper elevation={0} style={paper}>
			<div style={row}>
				{capsules?.map((doc) => {
					return (
						<div key={doc.id}>
							<CapsuleButton
								key={doc.id}
								capsuleName={doc.capsuleName}
								subCapsuleName={doc.subCapsuleName}
								coverImg={doc.coverPhoto}
							/>
						</div>
					);
				})}
			</div>
		</Paper>
	);
}
