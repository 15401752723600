import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Box, Paper} from '@mui/material';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { colorPack } from '../../theme/theme';
import SubCapsuleFormModal from '../forms/SubCapsuleFormModal';

export default function CapsuleHeaderNav() {
    const [ showSubCapsuleModal, setShowSubCapsuleModal ] = useState(false);

    const navigate = useNavigate(); 
    const { capsuleName } = useParams(); 
    const { secondaryDark, primaryMain } = colorPack; 

    const handleMediaClick = () => {
        navigate(`/${capsuleName}/media`);
    }
    const handleQuotesClick = () => {
        navigate(`/${capsuleName}/quotes`);
    }
    const handleItemsClick = () => {
        navigate(`/${capsuleName}/items`);
    }
    const handleJournalClick = () => {
        navigate(`/${capsuleName}/journal`);
    }

    const handleShowSubCapsuleModal = () => {
        setShowSubCapsuleModal(true);
    }
   
    const handleCloseSubCapsuleModal = () => setShowSubCapsuleModal(false);
   
    const navStackStyle = {
            position: 'fixed',      
            top: 56,
            right: 16,
            zIndex: 100,
    }

    const tabNavStyle = {
        position: 'fixed',      
        bottom: 75,
        right: 16,
        zIndex: 100,
    }

    const navIcons = {
        color: primaryMain,
        marginBottom: 0,
    }

    const iconBox = {
        display: 'flex',
        flexFlow: "column noWrap",
        alignItems: "center",
    }

    const iconActionStyle = {
        color: secondaryDark,
    }
    
    return (
        <Paper elevation={0} style={{paddingTop: 8}}>
            
            {showSubCapsuleModal && 
                <SubCapsuleFormModal 
                    closeModal={handleCloseSubCapsuleModal} 
                    isOpen={showSubCapsuleModal} 
                />
            }

            <Stack 
                style={navStackStyle}
                direction="row"
                alignItems="stretch"
                spacing={2}
            >
                <Box style={iconBox}>
                    <CreateNewFolderIcon 
                        fontSize='medium' 
                        style={iconActionStyle} 
                        onClick={handleShowSubCapsuleModal} 
                    />
                </Box>
            </Stack>
            <Stack 
                style={tabNavStyle}
                direction="column"
                alignItems="stretch"
                spacing={2}
            >
                <Box style={iconBox}>
                    <div>
                        <NoteAltIcon fontSize='medium' style={navIcons} onClick={handleJournalClick} />
                    </div>
                </Box>
                <Box style={iconBox}>
                    <div>
                        <FormatQuoteIcon fontSize='medium' style={navIcons} onClick={handleQuotesClick} />
                    </div>
                </Box>
                <Box style={iconBox}>
                    <div>
                        <DiamondOutlinedIcon fontSize='medium' style={navIcons} onClick={handleItemsClick} />
                    </div>
                </Box>
                <Box style={iconBox}>
                    <div >
                        <PermMediaOutlinedIcon fontSize='medium' style={navIcons} onClick={handleMediaClick} />
                    </div>
                </Box>
            </Stack>
        </Paper>
      )
}
