import React, { useState } from 'react';
import ImageCard from '../cards/ImageCard';
import { Paper, Typography, Stack } from '@mui/material';
import VideoCard from '../cards/VideoCard';
import UploadMediaFormModal from '../forms/UploadMediaFormModal';
import { colorPack } from '../../theme/theme';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';

const mediaBoxStyle = {
    display: 'flex',  
    flexFlow: 'row wrap', 
    justifyContent: 'flex-end', 
    alignItems:
     'center',
    marginTop: 16, 
    marginRight:8,
}
 
export default function MediaFolderTile({subMediaArr}) {
    const [ showDeleteBtn, setShowDeleteBtn ] = useState(false);
    const [ showUploadMediaForm, setShowUploadMediaForm ] = useState(false);
    const { primaryMain, secondaryDark } = colorPack;
     
    const handleShowDelete = () => {
        setShowDeleteBtn(!showDeleteBtn);
    }

    const handleHideUploadMediaForm = () => {
        setShowUploadMediaForm(false);
    }

return (
    <>
    <Stack direction='column' gap={2}>
        {subMediaArr?.length === 0 && 
            <Paper elevation={3} style={{padding: 8, width: '90vw', margin: 'auto', marginTop: 24}}>
                        <Typography variant='h5' component='div'>
                            Use the <span style={{color: primaryMain}}> + </span> button below to start adding your pictures & videos!
                        </Typography>
                    </Paper>
        }
        {subMediaArr?.length > 0 && 
            <>
                <div style={mediaBoxStyle}>
                    {showUploadMediaForm && 
                        <UploadMediaFormModal isOpen={showUploadMediaForm} closeModal={handleHideUploadMediaForm} /> 
                    }
                    <ModeEditOutlineRoundedIcon 
                        onClick={handleShowDelete} 
                        style={{
                            color: secondaryDark,
                            display: 'flex',
                            alignSelf: 'flex-end',

                        }} 
                    />
                </div>
                <Paper elevation={0} style={{padding: 0}}>
                    {subMediaArr?.length > 0 &&
                            subMediaArr?.map( obj =>  
                                obj?.url?.toLowerCase().includes('.png') ||
                                obj?.url?.toLowerCase().includes('.jpg') ||
                                obj?.url?.toLowerCase().includes('.svg') ?
                                    <div key={obj.url} style={{marginTop: 8}}>
                                        <ImageCard
                                            index={obj.index}
                                            id={obj.id}
                                            url={obj.url}
                                            showDeleteBtn={showDeleteBtn}
                                            favourite={obj.favourite}
                                            capsuleName={obj.capsuleName}
                                            subCapsuleName={obj.subCapsuleName}
                                            fileName={obj.fileName}
                                        />
                                    </div>
                                :
                                    <div key={obj.url} style={{marginTop: 8}}>
                                        <VideoCard
                                            index={obj.index}
                                            id={obj.id}
                                            url={obj.url}
                                            type='video/mp4'
                                            showDeleteBtn={showDeleteBtn}
                                            favourite={obj.favourite}
                                            capsuleName={obj.capsuleName}
                                            subCapsuleName={obj.subCapsuleName}
                                            fileName={obj.fileName}
                                        />
                                    </div>
                        )}
                </Paper>
            </>
        }
        </Stack>
    </>
    )
}

