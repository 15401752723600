import React, { useContext, useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import MediaTile from '../components/tiles/MediaTile';
import { useMedia } from '../hooks/useMedia';
import { useParams } from 'react-router-dom';
import { useAsync } from '../hooks/useAsync';
import { UserPrefsContext } from '../context/UserPrefsProvider.js';
import TrainingTileCapsule from '../components/Training/TrainingTileCapsule';

export default function MediaIndexPage() {
    const [ mediaArr, setMediaArr ] = useState([]);
    const { fetchCapsuleMedia, capsuleMedia, capsuleMediaListener } = useMedia(); 
    const { capsuleName } = useParams();
	const [showTraining, setShowTraining] = useState(false);
    const [ asyncState, setAsyncState ] = useState('');
    const [ showLoader, setShowLoader ] = useState(false);
    const loadingState = useAsync(asyncState); 
	const { newUserTrainingVisible } = useContext(UserPrefsContext);

    useEffect(() => {
        if(capsuleMedia?.length === 0){
            setAsyncState('pending');
            setShowLoader(true);
            fetchCapsuleMedia(capsuleName);
        }  
        if(capsuleMedia?.length > 0){
            setAsyncState('success');
            setShowLoader(false);
            setMediaArr(capsuleMedia);
        }
        if(capsuleMedia === null){
            setAsyncState('success');
            setShowLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[capsuleMedia]);

    useEffect(() => {
        setTimeout(() => {
            setAsyncState('success');
            setShowLoader(false);
        }, 3000);
    }, []);

    useEffect(() => {
        newUserTrainingVisible && setShowTraining(true);
        !newUserTrainingVisible && setShowTraining(false);
    }, [newUserTrainingVisible]);

    useEffect(() => {
        const unsubscribe = capsuleMediaListener(capsuleName);
        return () => {
            unsubscribe(); 
        }
    }, []);
    
    return (
        <>
            {showLoader && loadingState}
            {showTraining && <TrainingTileCapsule />}
            <Paper sx={{display:'flex', flexFlow:'row wrap', justifyContent:'center',alignItems:'center'}} elevation={0}>
                <MediaTile 
                    mediaArr={mediaArr}
                />
            </Paper>
        </>
    )
}
 