import React from 'react';
import { Box, Modal }from '@mui/material';
import UploadMediaForm from './UploadMediaForm';

const style = {
  display: 'flex',
  flexFlow: 'column noWrap',
  alignItems: 'center',
  maxWidth: '100%vw',
  marginTop: 240,
};

export default function UploadMediaFormModal({closeModal, isOpen}) {
    const handleClose = () => {
      setTimeout(() => {
            closeModal(); 
      }, 1000);
    }

  return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            onBlur={(e) => e.preventDefault(e)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box style={style}>
            <UploadMediaForm closeModal={handleClose} />
        </Box>
      </Modal>
  );
}