import React from 'react'
import { Typography } from '@mui/material'

export default function QuoteComp({quote, context, author, date}) {
  return (
    <>
            <Typography variant='h5' component='h5'>
                "{quote}"
            </Typography>
            <Typography variant='body1' component='p' style={{marginLeft:8, marginTop: 8}}>
                {context}
            </Typography>
            <Typography style={{display: 'flex', justifyContent: 'flex-end'}} variant='body1' component='div'>
                {author}, {date}
            </Typography>
    </>
  )
}
