import React from 'react';
import { Typography, Paper, Stack } from '@mui/material';
import { colorPack } from '../../theme/theme';
import ButtonMain from '../buttons/ButtonMain';

const { secondaryMain } = colorPack;

const paperStyle = {
    width: 340, 
    margin: 'auto',
    padding: 8,
    backgroundColor: 'white', 
    color: 'black',
    zPosition: 110,
}

export default function ErrorCard({message, onClick}) {

    return (
            <Paper elevation={6} style={paperStyle}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Typography variant='h6' component='div'>
                        {message}
                    </Typography>
                    <ButtonMain 
                        bgColor={secondaryMain}
                        color={"white"}
                        label={'close'}
                        width={'100%'}
                        onClick={onClick}
                    />
                </Stack>
            </Paper>
    )
}
