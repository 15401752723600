import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Box, Stack, Button, Typography } from '@mui/material';
import { useMotion } from '../../hooks/useMotion.js';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import theme from '../../theme/theme';
import UploadCoverPhotoFormModal from '../forms/UploadCoverPhotoFormModal';
import DeleteCapsuleForm from '../forms/DeleteCapsuleForm.jsx';

export default function EditCapsuleMenu({ closeModal, capsuleName }) {
	const { zoomIn } = useMotion();
	const [showMenu, setShowMenu] = useState(true);
	const [showMediaForm, setShowMediaForm] = useState(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const handleCloseModal = () => {
		closeModal();
	};

	const handleShowConfirmDelete = () => {
		setShowMenu(false);
		setShowConfirmDelete(true);
	};

	const handleCancelDelete = () => {
		setShowConfirmDelete(false);
		closeModal();
	};

	return (
		<motion.div
			style={{ padding: 0 }}
			variants={zoomIn}
			initial="hidden"
			animate="visible"
			exit="exit"
		>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="stretch"
				spacing={0.5}
				padding={0}
				width={'100%'}
			>
				{showConfirmDelete && (
					<DeleteCapsuleForm
						closeModal={closeModal}
						cancelDelete={handleCancelDelete}
						capsuleName={capsuleName}
					/>
				)}

				{showMediaForm && (
					<UploadCoverPhotoFormModal
						closeModal={handleCloseModal}
						isOpen={showMediaForm}
						capsuleBtnName={capsuleName}
						isCover={true}
						style={{ zIndex: 100 }}
					/>
				)}

				{showMenu && (
					<>
						<Box
							onClick={() => setShowMediaForm(true)}
							sx={{
								backgroundColor: theme.palette.background.tintLight,
								'&:hover': {
									backgroundColor: theme.palette.secondary.light,
								},
							}}
						>
							<AddAPhotoIcon sx={{ pt: 1 }} />
							<Typography sx={{ mb: 2 }} component="Box" variant="subtitle1">
								Change Cover Image
							</Typography>
						</Box>

						<Box
							onClick={handleShowConfirmDelete}
							sx={{
								backgroundColor: theme.palette.background.tintLight,
								'&:hover': {
									backgroundColor: theme.palette.primary.light,
									color: 'darkRed',
								},
							}}
						>
							<DeleteForeverIcon sx={{ pt: 0.5, color: 'darkRed' }} />
							<Typography component="Box" variant="subtitle1">
								Delete Forever
							</Typography>
						</Box>
						<Box sx={{ mt: 8 }} />
						<Box
							sx={{
								display: 'flex',
								flexFlow: 'column noWrap',
								justifyContent: 'center',
								alignItems: 'stretch',
								backgroundColor: theme.palette.primary.main,
								'&:hover': {
									backgroundColor: theme.palette.primary.dark,
								},
							}}
						>
							<Button onClick={handleCloseModal} sx={{ color: 'white' }}>
								Close
							</Button>
						</Box>
					</>
				)}
			</Stack>
		</motion.div>
	);
}
