import { useEffect, useState, useCallback, useContext } from 'react';
import { arrayRemove, arrayUnion, getDoc, doc, updateDoc, collection } from "firebase/firestore";
import { db } from '../services/firebase';
import { AuthContext } from '../context/AuthProvider';

/**
 * @param {string} uid - The Google user id of the signed-in user 
 * @param {Object} db - The targeted firestore instance the firestore app is associated with
*/

export const useFavourite = () => {
    const [ allFavourites, setAllFavourites ] = useState([]);
    const authContext = useContext(AuthContext);
    const uid = authContext.uid; 
    const rootPath = `users/${uid}/capsules`;
   
    /**
     * will toggle favourite status
     * @param {Object} options - obj key/value pairs to match for updates (must include "url & id" unless cardType imgCard | videoCard)
     * @param {Boolean} favourite - current favourite status
     * @param {string} cardType - component cardType that will display the data
     */
    const toggleAllFavourites = async (options, favourite, cardType) => {
        const path = `${rootPath}/allFavourites`;
        const favouritesRef = doc(db, path);
        let objOptions = options; 
        if(cardType === 'imgCard' || 'videoCard'){
            objOptions = {...options, favourite}; 
        }
        let action;
        (favourite) ? action = arrayRemove 
            : action = arrayUnion; 
        await updateDoc(favouritesRef, {
            allFavourites: action({
                ...objOptions,
                favourite: true,
                cardType: cardType,
            }
        )})
        .catch((error) => console.log("Error toggling favourite status on. Error: ", error));
    }

    /**
     * will update the item's favourite status in the doc where it lives
     * @param {string} capsuleName - name of the target capsule
     * @param {Boolean} favourite - current favourite status  
     * @param {string} id - document id 
     */
    const toggleItemStatus = async (capsuleName, favourite, id) => {
        const collectionRef = collection(db,`${rootPath}/${capsuleName}/items`); 
        const docRef = doc(collectionRef, id);
        await updateDoc(docRef, 'favourite', !favourite)
        .catch((error) => console.log("Error updating favourite status for Item.  Error: ", error));
    }

    /**
     * will update the journal entry's favourite status in the doc where it lives
     * @param {string} capsuleName - name of the target capsule
     * @param {Boolean} favourite - current favourite status  
     * @param {string} id - document id 
     */
    const toggleJournalStatus = async (capsuleName, favourite, id) => {
        const collectionRef = collection(db,`users/${uid}/capsules/${capsuleName}/journal`); 
        const docRef = doc(collectionRef, id);
        await updateDoc(docRef, 'favourite', !favourite)
        .catch((error) => console.log("Error updating favourite status for Journal entry.  Error: ", error));
    }

    /**
     * will update the quotes's favourite status in the doc where it lives
     * @param {string} capsuleName - name of the target capsule
     * @param {Boolean} favourite - current favourite status  
     * @param {string} id - document id 
     */
        const toggleQuoteStatus = async (capsuleName, favourite, id) => {
            const collectionRef = collection(db,`users/${uid}/capsules/${capsuleName}/quotes`); 
            const docRef = doc(collectionRef, id);
            await updateDoc(docRef, 'favourite', !favourite)
            .catch((error) => console.log("Error updating favourite status for quote.  Error: ", error));
        }
    
    const toggleMediaStatus = async (options, favourite) => {
        const {url, capsuleName, fileName, subCapsuleName} = options; 
        let mediaObj = {capsuleName, fileName, url}
        let path = `${rootPath}/${capsuleName}`;
        if(subCapsuleName?.length > 0){
            path = `${rootPath}/${capsuleName}/subCapsules/${subCapsuleName}`;
            mediaObj = {...mediaObj, subCapsuleName};
        }
        const mediaRef = doc(db, path);
        updateDoc(mediaRef, {
                media: arrayUnion({
                    ...mediaObj,
                    favourite: !favourite,
                }, {merge: true}
        )})
        updateDoc(mediaRef, {
            media: arrayRemove({
                ...mediaObj,
                favourite: favourite,
            }, {merge: true}
        )})
        .catch((error) => console.log("Error adding el to media array. Error: ", error))

    }
    
    const fetchAllFavourites = useCallback(() => {
        (async function getAllFavourites(){
            const docRef = doc(db, `${rootPath}/allFavourites`)
            const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    let allFavData = docSnap.data().allFavourites; 
                    let reorderFavs = allFavData?.reverse(); 
                    setAllFavourites(reorderFavs);
                    return reorderFavs;
                }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        fetchAllFavourites();
    },[]);

    return {
        toggleQuoteStatus,
        toggleJournalStatus,
        toggleItemStatus,
        toggleMediaStatus,
        toggleAllFavourites,
        allFavourites,
        fetchAllFavourites,
    }
}


export default useFavourite;