import React, { useState, Fragment, useEffect } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import ItemCard from '../cards/ItemCard';
import QuoteCard from '../cards/QuoteCard';
import JournalCard from '../cards/JournalCard';
import VideoCard from '../cards/VideoCard';
import ImageCard from '../cards/ImageCard';
import { colorPackTwo } from '../../theme/theme';
import LoadingCard from '../cards/LoadingCard';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined'; 
import { useFavourite } from '../../hooks/useFavourite.js';
import { v4 } from 'uuid';
const { contrastLight, secondaryLight } = colorPackTwo; 


const itemStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    marginTop: 4,
    backgroundColor: contrastLight, 
    padding: 4,
}

const quoteStyle ={
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    justifyContent:'center',
    marginTop: 4,
    backgroundColor: secondaryLight, 
    padding: 4,
}

const journalStyle ={
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    justifyContent:'center',
    marginTop: 4,
    backgroundColor: secondaryLight, 
    padding: 4,
}

const mediaCardStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    marginTop: 4,
    backgroundColor: contrastLight, 
    padding: 4,
}

export default function FavouritesTile() {
    const [favourites, setFavourites] = useState([]);
    const { primaryMain } = colorPackTwo; 
    const { fetchAllFavourites, allFavourites } = useFavourite(); 
    
    useEffect(() => {
        fetchAllFavourites();
    },[fetchAllFavourites])

    useEffect (() => {
        if(allFavourites?.length > 0){
            setFavourites(allFavourites);
        }
    },[allFavourites]);
    
    return (
        <>
        {favourites === undefined && <LoadingCard />}
        {favourites?.length === 0 && 
            <Paper elevation={3} sx={{p:8,m:'auto'}}>
                <Typography variant='h5' component='div'>
                    Use the <LocalFireDepartmentOutlinedIcon style={{color: primaryMain} } /> icon on your favourite memories to add them here.
                </Typography>
            </Paper>
        }
        {favourites?.length > 0 &&
            <Box sx={{p:0}}>
                <Paper elevation={0} sx={{p:0}}>
                    {favourites?.map((fav) => 
                        <Fragment key={v4()}>
                        {(fav?.cardType === 'quoteCard') && 
                            <Paper elevation={3} style={quoteStyle}>
                                <QuoteCard
                                    id={fav.id}
                                    capsuleName={fav.capsuleName}
                                    favourite={true}
                                    cardType={fav.cardType}
                                    quote={fav.quote}
                                    context={fav.context}
                                    author={fav.author}
                                    date={fav.date}
                                />
                            </Paper>
                        }
                        {(fav?.cardType === 'itemCard') && 
                            <Paper elevation={3} style={itemStyle}>
                                <ItemCard 
                                    id={fav.id}
                                    url={fav.url}
                                    capsuleName={fav.capsuleName}
                                    favourite={true}
                                    fileName={fav.fileName}
                                    cardType={fav.cardType}
                                    title={fav.title}
                                    aboutItem={fav.aboutItem}
                                    date={fav.date}
                            /> 
                            </Paper>
                        }
                        {(fav?.cardType === 'journalCard') && 
                            <Paper elevation={3} style={journalStyle}>
                                <JournalCard
                                    id={fav.id}
                                    capsuleName={fav.capsuleName}
                                    favourite={true}
                                    cardType={fav.cardType}
                                    entry={fav.entry}
                                    title={fav.title}
                                    date={fav.date}
                                />
                            </Paper>
                        }
                        {(fav?.cardType === 'imgCard') && 
                            <Paper elevation={3} style={mediaCardStyle}>
                                <ImageCard
                                    url={fav.url}
                                    favourite={true}
                                    capsuleName={fav.capsuleName}
                                    subCapsuleName={fav.subCapsuleName}
                                    fileName={fav.fileName}
                                    cardType={fav.cardType}
                                />
                            </Paper>
                        }
                        {(fav?.cardType === 'videoCard') && 
                            <Paper elevation={3} style={mediaCardStyle}>
                                <VideoCard
                                    url={fav.url}
                                    favourite={true}
                                    capsuleName={fav.capsuleName}
                                    subCapsuleName={fav.subCapsuleName}
                                    fileName={fav.fileName}
                                    cardType={fav.cardType}
                                />
                            </Paper>
                        }
                    </Fragment>
                )}
            </Paper>
        </Box>
        }     
    </>
    )
}

 