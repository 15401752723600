import { useReducer, createContext, useEffect, useContext } from 'react';
import { db } from '../services/firebase';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { AuthContext } from './AuthProvider';

const PREFS_STATE = {
	theme: 'light',
	language: 'en',
	newUserTrainingVisible: true,
};

const prefsReducer = (state, action) => {
	switch (action.type) {
		case 'SET_THEME':
			return {
				...state,
				theme: action.payload,
			};
		case 'SET_LANGUAGE':
			return {
				...state,
				language: action.payload,
			};
		case 'SET_NEWUSERTRAININGVISIBLE':
			return {
				...state,
				newUserTrainingVisible: !state.newUserTrainingVisible, // Toggle the visibility
			};
		case 'INITIALIZE_STATE_FROM_DB':
			return {
				...state,
				...action.payload, // Initialize state with values fetched from the database
			};
		default:
			return state;
	}
};

export const UserPrefsContext = createContext();
UserPrefsContext.displayName = 'UserPrefsContext';

export const UserPrefsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(prefsReducer, PREFS_STATE);

	const authContext = useContext(AuthContext);
	const uid = authContext.uid;

	const setTheme = (theme) => dispatch({ type: 'SET_THEME', payload: theme });
	const setLanguage = (language) =>
		dispatch({ type: 'SET_LANGUAGE', payload: language });

	// Function to toggle training visibility
	const toggleNewUserTrainingVisible = async () => {
		const newValue = !state.newUserTrainingVisible;
		await setDoc(
			doc(db, 'users', uid),
			{ newUserTrainingVisible: newValue },
			{ merge: true }
		);
		dispatch({ type: 'SET_NEWUSERTRAININGVISIBLE' });
	};
	

	// Effect to fetch initial state from Firestore
	useEffect(() => {
		if (!uid) return; // Exit if no user ID

		const docRef = doc(db, 'users', uid);
		const fetchInitialState = async () => {
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				dispatch({ type: 'INITIALIZE_STATE_FROM_DB', payload: docSnap.data() });
			}
		};

		fetchInitialState();
	}, [uid]);

	const value = {
		...state,
		setTheme,
		setLanguage,
		toggleNewUserTrainingVisible,
	};

	return (
		<UserPrefsContext.Provider value={value}>
			{children}
		</UserPrefsContext.Provider>
	);
};
