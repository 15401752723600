import React, { useState } from 'react';
import { Box, FormControl, TextField, Paper } from '@mui/material';
import ButtonMain from '../buttons/ButtonMain';
import { colorPack } from  '../../theme/theme';
import { useFirestore } from '../../hooks/useFirestore';
import { useParams } from 'react-router-dom';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

const paperStyle = {
        marginBottom: 100, 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        padding: 8,
        width: '95%', 
        backgroundColor: 'lightGrey',
}

export default function CreateSubCapsuleForm({closeModal}) {
    const { secondaryDark, primaryMain } = colorPack; 
    const { capsuleName } = useParams(); 
    const { createSubCapsule } = useFirestore();
    const [ newCapsuleName, setNewCapsuleName ] = useState('');
    
     const handleSubmit = async (e) => {
        e.preventDefault();
        try {
                createSubCapsule({
                capsuleName: capsuleName,
                newCapsuleName: newCapsuleName,
            });
                closeModal(); 
        } 
        catch (error) {
            console.log('Error creating SubCapsule.', error);
        }
    }

    return (
            <Paper elevation={9} style={paperStyle}>
                <FormControl fullWidth >
                    <TextField 
                        id="newSubCapsuleName" 
                        label="Folder Name" 
                        sx={{marginBottom: 1, backgroundColor: 'white'}}
                        value={newCapsuleName} 
                        onChange={(e) => setNewCapsuleName(e.target.value)}
                        variant="outlined" 
                /> 
                </FormControl>
                <Box sx={{display: 'flex', flexFlow: 'row noWrap', justifyContent: 'space-between' }}>
                   <span>
                        <ButtonMain 
                            width={'200px'} 
                            type='submit'
                            onClick={handleSubmit} 
                            color={'white'} 
                            bgColor={secondaryDark} 
                            label={'Create Folder'} 
                            startIcon={<CreateNewFolderIcon style={{marginBottom: 2}} />}
                        />
                    </span>
                    <span>
                        <ButtonMain 
                            onClick={closeModal} 
                            width={'50px'} 
                            type='submit' 
                            color={'white'} 
                            bgColor={primaryMain} 
                            label={"Cancel"} 
                        />
                    </span>
                </Box>
            </Paper>
    );
}