import React from 'react'

const iconStyle = {
  maxWidth: 24,
  maxHeight: 24,
}

export default function NavHomeIcon({onClick}) {
  return (
    <img onClick={onClick} src="\images\navHome.svg" style={iconStyle} alt='capsules' type='button' />
  )
}
