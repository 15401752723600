import React from 'react';
import { Box, Modal } from '@mui/material';
import CreateSubCapsuleForm from './CreateSubCapsuleForm';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 400,
    padding: 1,
    zIndex: 100,
};

export default function SubCapsuleFormModal({closeModal, isOpen}) {
  const handleClose = () => closeModal();

  return (
    <div>
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <CreateSubCapsuleForm closeModal={handleClose} />
        </Box>
      </Modal>
    </div>
  );
}