import React from 'react';
import { Typography } from '@mui/material';
import { colorPack } from '../../theme/theme';

const { mainText } = colorPack;

export default function CursiveTitleCard({title, color, isVisible}) {
    const fontColor = color || mainText;
    const cursiveFont = { 
        position: 'relative',
        fontFamily: `'Marhey', cursive`,
        fontSize: '1.45rem',
        color: fontColor,
        marginBottom: 0,
        marginTop: 24,
    }
    
  return (
    <>
    {!isVisible && 
      <Typography component='div' style={cursiveFont}>
         {title}
      </Typography>
    }
    </>
  )
}
