import React from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { Paper, Stack } from "@mui/material";
import FormInputText from "./formComponents/FormInputText";
import FormInputTextArea from './formComponents/FormInputTextArea';
import { colorPack } from '../../theme/theme';
import ButtonMain from '../buttons/ButtonMain'; 
import { useJournal } from "../../hooks/useJournal";
import { motion } from 'framer-motion';
import { useMotion } from '../../hooks/useMotion';

const { secondaryMain, primaryLight } = colorPack;

const paperStyle ={
    display: 'flex',
    flexFlow: 'column noWrap',
    alignItems: 'stretch',
    justifyContent: 'center',
    padding: 8, 
    backgroundColor: primaryLight, 
}

export default function JournalEntryForm({closeModal}) {
    const { addJournalEntry } = useJournal(); 
    const { capsuleName } = useParams(); 
    const { zoomIn } = useMotion();
    const emptyJournalEntry = {
        title: '',
        entry: '',
        date: '',
        favourite: false,
        capsuleName: '',
    }
    
    const { handleSubmit, control } = useForm({ defaultValues: emptyJournalEntry});
    
    const onSubmit = () => {
        handleSubmit((formData) => {
            const formResult = Object.assign(formData);
            addJournalEntry(capsuleName, formResult);
            closeModal(); 
        })();
    };

return (
        <motion.div
            style={{padding: 0}}
            variants={zoomIn}
            initial='hidden'
            animate='visible' 
            exit='exit'
        >
        <Paper style={paperStyle}>
            <Stack gap={2}>
                <FormInputText style={{backgroundColor: 'white'}} 
                    name={'title'} 
                    control={control} 
                    label={"Title"}
                />
                <FormInputTextArea 
                    name={'entry'} 
                    control={control} 
                    label={"Journal Entry"}
                />
                <ButtonMain 
                    type='submit' 
                    onClick={handleSubmit(onSubmit)}
                    width={'100%'} 
                    label={'Submit'} 
                    variant='contained' 
                    bgColor={secondaryMain}  
                    color='white' 
                    text="Submit Request" 
                />
            </Stack>
        </Paper>
    </motion.div>
  );
}