import React from 'react';
import ModalMain from './ModalMain';
import JournalEntryForm from './JournalEntryForm';

export default function JournalEntryFormModal({closeModal, isOpen}) {
    const handleClose = () => closeModal();

    return (
            <ModalMain 
                isOpen={isOpen}
                closeModal={handleClose}
                modalElement={
                    <JournalEntryForm 
                        closeModal={closeModal} 
                        isOpen={isOpen} 
                    />
                }
            />
  );
}
