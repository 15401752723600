import React, { useContext, useState, useEffect } from 'react';
import { ref, getDownloadURL, uploadBytesResumable, getStorage } from "firebase/storage";
import ButtonMain from '../buttons/ButtonMain';
import { colorPack } from '../../theme/theme';
import { Box, Paper, Typography, Input, FormControl, LinearProgress } from '@mui/material';
import { useStorage } from '../../hooks/useStorage';
import ErrorCard from '../cards/ErrorCard';
import { AuthContext } from '../../context/AuthProvider.js';

const formStyle = {
        width: '100%', 
        maxWidth: 400,
        height: '100%',
        maxHeight: '95%vh',
        overflow: 'hidden',
        display: 'flex',
        flexFlow: 'column noWrap',
        alignContent: 'center', 
        alignItems: 'stretch',
}
const imgBox = { 
        display: 'flex',
        flexFlow: 'column noWrap',
        width: '90vw',
        justifyContent: 'stretch', 
        alignItems: 'stretch',
}

const imgStyle = {
    width: '100%',
    maxWidth: 400,
  };

export default function UploadCoverPhotoForm({closeModal, capsuleBtnName}) {
    const authContext = useContext(AuthContext);
    const uid = authContext.uid;
    const { secondaryMain, primaryLight } = colorPack; 
    const [ showError, setShowError ] = useState(false);
    const [ imageToUpload, setImageToUpload] = useState('')
    const [ mediaUrl, setMediaUrl] = useState(null);
    const [ mediaName, setMediaName ] = useState('');
    const [ imgUrl, setImgUrl ] = useState('');
    const [ progresspercent, setProgresspercent] = useState(0);
    const [ showImageUploadingPreview, setShowImageUploadingPreview ] = useState(false);
    const [ showChooseFile, setShowChooseFile ] = useState(true);
    const [ showUploadBtn, setShowUploadBtn ] = useState(false); 
    const [ showProgress, setShowProgress ] = useState(false);
    
    const { editCoverPhoto } = useStorage();

    const handleCloseForm = () => {
        closeModal(); 
    }

    const handleMediaUpload = (e) => {
        const media = e.target.files[0];
        const fileName = media.name;
        const allowedTypes = /(\.jpg|\.png)$/i; 
        if (!allowedTypes.exec(fileName)) {
            setShowError(true);
            return false;
        }
        const url = URL.createObjectURL(e.target.files[0]);
        setShowImageUploadingPreview(true);
        setImgUrl(url);
        setShowChooseFile(false);
        setImageToUpload(media);
        setMediaName(media.name);
        setShowUploadBtn(true);
        setShowProgress(true);
    }
     
    const metadata = {
        customMetadata: {
            'mediaName': mediaName,
        }
    };
    
    const handleSubmit = (e) => {
        e.preventDefault(); 
        setShowUploadBtn(false);
        const storage = getStorage();
        const imageRef = ref(storage, `users/${uid}/media/${mediaName}`); 
        const uploadTask = uploadBytesResumable(imageRef, imageToUpload, metadata);
        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                setShowProgress(false);
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setMediaUrl(downloadURL);
                editCoverPhoto(downloadURL, capsuleBtnName);
            });
        })
    }

    const handleBlur =(e) => {
        e.preventDefault();
    }

    useEffect(() => {
        if(progresspercent === 100){
            setShowImageUploadingPreview(false);
        }
    },[progresspercent]); 
    
return (
    <>
    {showError && 
        <ErrorCard 
            message={'Invalid file type. Please select a jpg or png for your cover photo.'} 
            onClick={() => setShowError(false)}
        />
    }
    <Paper elevation={9} style={{padding: 8}} >
        <FormControl>
            <form 
                style={formStyle} 
                onBlur={handleBlur}
                onSubmit={handleSubmit}
            >
        {showChooseFile &&
                <Paper elevation={9}>
                    <label htmlFor="name" />
                    <Input
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleMediaUpload}
                        type='file'
                        style={{
                            backgroundColor: primaryLight, 
                            padding: 8, 
                            color: 'white',
                            fontSize: '1.1rem',
                                }}
                        id='name'
                    />
                </Paper>
        }  

                <div style={{marginTop: 24}} />

            {showImageUploadingPreview && 
                <>
                    <div onClick={() => handleCloseForm()} style={{width: '15%', marginLeft: 'auto', marginBottom: 8}}>
                        <Typography variant='button' style={{color: primaryLight}}>Cancel</Typography>
                    </div>
                    <Box sx={imgStyle}>
                        <img src={imgUrl} alt='uploaded file' height='100%' width='100%' />
                    </Box>
                </>
            }

            {showUploadBtn && 
                <div style={{marginTop: 8}}>
                    <ButtonMain 
                        onBlur={handleBlur}
                        label={"Save Cover Photo"} 
                        onClick={handleSubmit} 
                        width={'100%'}
                        bgColor={secondaryMain} 
                        color={'white'} 
                        type='submit' 
                    />
                </div> 
            }        
            
            </form>
            
                {showProgress &&
                    <Typography style={imgBox} variant='h4' component='div'>
                        <Box sx={imgStyle}>
                            <LinearProgress />
                            {progresspercent}%
                        </Box>
                    </Typography>
                }
                {(!showProgress && mediaUrl !== null) &&
                    <>
                        <div style={imgBox}>
                            <Typography variant='h5' component='h5'>
                                Updated Successfully
                            </Typography>
                            <div style={{
                                display: 'flex',
                                flexFlow: 'column noWrap',
                                alignItems: 'stretch',
                                justifyContent: 'center',
                                border: '1px solid grey', 
                                borderRadius: 4, 
                                width: '100%', 
                                maxWidth:370, 
                                margin: 8
                            }}>
                                <ButtonMain 
                                    bgColor={secondaryMain}
                                    onBlur={handleBlur}
                                    width={'100%'}
                                    color={'white'} 
                                    label={"Close Window"} 
                                    onClick={() => {
                                        setMediaUrl(null);
                                        handleCloseForm();
                                        }} 
                                    type='button' 
                                />
                            </div>
                        </div>
                    </>
                }
            </FormControl>
      </Paper>
</>
    );
}
