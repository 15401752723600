import React from 'react';
import { useParams } from 'react-router-dom';
import SubCapsulesTile from '../tiles/SubCapsulesTile';

export default function MediaFolderNav({subCapsules}) {
  const { capsuleName } = useParams(); 
   
    return (
            <SubCapsulesTile  
                capsuleName={capsuleName} 
                subCapsules={subCapsules} 
            />
    )
}
  