import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { colorPack } from '../../theme/theme';
import TrainingHelpButton from '../buttons/HelpButton';
import { useSettings } from '../../hooks/useSettings.js';
const { primaryMain } = colorPack;

const headerStyle = {
	position: 'fixed',
	top: 0,
	display: 'flex',
	margin: 0,
	marginBottom: 32,
	padding: 8,
	width: '100%',
	height: 40,
	background: primaryMain,
	alignItems: 'center',
	justifyContent: 'space-between',
	zIndex: 100,
};

export default function Header() {
	const { signOut } = useAuth();
	const { capsuleName, subCapsuleName } = useParams();
	const navigate = useNavigate();

	const { fetchNewUserTrainingVisible } = useSettings();

	useEffect(() => {
		let isActive = true;
		if (isActive) {
			fetchNewUserTrainingVisible();
		}
		return () => {
			isActive = false;
		};
	}, []);

	const handleNavClick = () => {
		navigate(`/${capsuleName}/media`);
	};

	function handleSignOut() {
		signOut();
	}

	return (
		<Box style={headerStyle}>
			<Typography variant="body2" style={{ padding: 8, color: 'white' }}>
				<span onClick={handleNavClick}>{capsuleName}</span>
				<span>{subCapsuleName?.length > 0 && '... ' + subCapsuleName}</span>
			</Typography>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flex-end',
					justifyContent: 'flex-end',
					gap: 2,
				}}
			>
				<TrainingHelpButton />
				<ExitToAppOutlinedIcon
					style={{ color: 'white' }}
					fontSize="medium"
					onClick={handleSignOut}
				/>
			</Box>
		</Box>
	);
}
