import React, { useState, useContext, useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import MediaFolderNav from '../components/nav/MediaFolderNav';
import UploadMediaFormModal from '../components/forms/UploadMediaFormModal';
import CursiveTitleCard from '../components/cards/CursiveTitleCard';
import FabButton from '../components/buttons/FabButton';
import { Paper } from '@mui/material';
import { UserPrefsContext } from '../context/UserPrefsProvider.js';

export default function MediaPage() {
	const [showUploadMediaForm, setShowUploadMediaForm] = useState(false);
	const { capsuleName } = useParams();
	const handleCloseModal = () => setShowUploadMediaForm(false);
	const { newUserTrainingVisible } = useContext(UserPrefsContext);
	const [showTraining, setShowTraining] = useState(false);

  useEffect(() => {
        newUserTrainingVisible && setShowTraining(true);
        !newUserTrainingVisible && setShowTraining(false);
    }, [newUserTrainingVisible]);

	return (
		<Paper elevation={0} style={{ padding: 8 }}>
			<CursiveTitleCard 
				title={`${capsuleName} Media`}
				isVisible={showTraining}
				/>
			<MediaFolderNav />
			<Outlet />
			<UploadMediaFormModal
				isOpen={showUploadMediaForm}
				closeModal={handleCloseModal}
			/> 
			<FabButton
				onClick={() => setShowUploadMediaForm(true)}
				style={{ backgroundColor: 'red' }}
				size="small"
				aria-label="add"
			/>
		</Paper>
	);
}
