import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { useFirestore } from '../../hooks/useFirestore';
import MediaFolderNavBtn from '../buttons/MediaFolderNavBtn';

const paperStyle = {
	display: 'flex',
	flexFlow: 'column noWrap',
	justifyContent: 'center',
	width: '100%',
	gap: 8,
	marginTop: 16,
};

const row = {
	display: 'flex',
	flexFlow: 'row wrap',
	width: '100%',
	justifyContent: 'flex-start',
	gap: 8,
};

export default function SubCapsulesTile({ capsuleName }) {
	const [subCapsuleArr, setSubCapsuleArr] = useState([]);
	const { fetchUserSubCapsules, userSubCapsules, subCapsuleListener } =
		useFirestore();

	useEffect(() => {
		fetchUserSubCapsules(capsuleName);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setSubCapsuleArr(userSubCapsules);
	}, [userSubCapsules]);

	useEffect(() => {
		const unsubscribe = subCapsuleListener(capsuleName);
		return () => {
			unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Paper elevation={0}>
			{subCapsuleArr?.length > 0 ? (
				<>
					<Paper elevation={0} style={paperStyle}>
						<div style={row}>
							{subCapsuleArr?.map((doc) => {
								return (
									<MediaFolderNavBtn
										key={doc.id}
										subCapsuleName={doc.subCapsuleName}
										capsuleName={capsuleName}
									/>
								);
							})}
						</div>
					</Paper>
				</>
			) : (
				<div />
			)}
		</Paper>
	);
}
