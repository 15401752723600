import React from 'react'
    
export default function ImageComp({url}) {
    return (
        <img
            src={url} 
            loading="lazy"
            style={{objectFit: 'cover', width: '100%', height: '100%'}} 
            alt=''
        />
    )
}
