import React from 'react';
import ItemCard from '../cards/ItemCard';
import { Paper } from '@mui/material';
import { useParams } from 'react-router-dom'; 
import { colorPack } from '../../theme/theme';

const rootStyle = { 
    display: 'flex', 
    flexFlow: 'column noWrap', 
    justifyContent: 'center', 
    alignItems: 'stretch',
    marginTop: 16,
    marginBottom: 64,
    padding: 0,
} 
 
export default function ItemsTile({itemData}) {
    const { secondaryLight } = colorPack;
    const { capsuleName } = useParams(); 
    
return (
        <div style={rootStyle}>
            {itemData?.map((doc, index) => {
                return (
                    <Paper elevation={0} 
                        style={{backgroundColor: secondaryLight, marginTop: 8, padding: 4}} 
                        key={doc.id}
                    >
                        <ItemCard
                            index={index + 1}
                            id={doc.id}
                            title={doc.title}
                            aboutItem={doc.aboutItem}
                            date={doc.date}
                            url={doc.url}
                            favourite={doc.favourite}
                            capsuleName={capsuleName}
                            fileName={doc.fileName}
                        />
                    </Paper>
                );
            })}
        </div>
    )
}
