import React from 'react';
import { Paper, Modal } from '@mui/material';

const boxStyle = {
	display: 'flex',
	flexFlow: 'column noWrap',
	justifyContent: 'center',
	alignContent: 'stretch',
	alignItems: 'stretch',
	width: '80vw',
	maxWidth: 350,
	backgroundColor: 'white',
	padding: 8,
};

const modalStyle = {
	display: 'flex',
	flexFlow: 'column noWrap',
	justifyContent: 'center',
	alignItems: 'center',
};

export default function MsgModal({ closeModal, isOpen, userMsg }) {
	const handleClose = () => {
		closeModal();
	};
	return (
		<Modal
			sx={{ overflowY: 'scroll' }}
			disableScrollLock={false}
			style={modalStyle}
			onBlur={(e) => e.preventDefault()}
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Paper style={boxStyle} component="form">
				{userMsg}
			</Paper>
		</Modal>
	);
}
