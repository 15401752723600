
// custom hook used to add motion effects to components. Eg. Dashboard buttons zoom-in from invisible to final size when they are painted by the browser.  Adds more fluid & engaging feel when painting components in the browser

export const useMotion = () => {

    const zoomIn = {    visible:  {  
                            opacity: 1, scale: ['40%', '42%' ,'45%', '50%', '60%', '70%', '85%', 
                                                '105%', '110%', '110%', '109%', '108%','107%', 
                                                '105%', '102%', '101%', '99%', '100%' ],
                        },
                        hidden: { opacity: 0 }, transition: { duration: 0.3 },  
    }
    
    const zoomText = {  rest: { scale: 1 }, hover: { scale: 1.2 }, pressed: { scale: 0.85 } }
    
    const slideDown = {     hidden: { y: '-100vh', opacity: '0', }, 
                            visible: { y: '0', opacity: 1, 
                                        transition: { duration: 1.2, type: 'spring', damping: 10, stiffness: 150, } 
                            }, 
    }
    
    const slideUp = {  hidden: { y: '100vh', opacity: '0', }, 
                            visible: {  y: '0', opacity: 1, 
                                        transition: { duration: 0.7, type: 'spring', damping: 25, stiffness: 400, }
                            },
    }
    
    const slideFromRight = {    hidden: { x: '+100vw', opacity: '0', }, 
                                visible: { x: '0', opacity: 1,
                                        transition: {  duration: 1.4, type: 'spring', damping: 25, stiffness: 400, }
                                },
    }
    
    const slideFromLeft = {     hidden: { x: '-100vw', opacity: '0', }, 
                                visible: { x: '0', opacity: 1, 
                                    transition: { duration: 1.4, type: 'spring', damping: 25, stiffness: 400, } 
                                },
    }

    const delayFromLeft = {    hidden: { x: '-100vw', opacity: '0', }, 
                                visible: { x: '0', opacity: 1, 
                                    transition: { delay: 1.25, duration: 1.4, type: 'spring', damping: 25, stiffness: 300, } 
                            },
    }

    const delayFromRight = {    hidden: { x: '+100vw', opacity: '0', }, 
                                visible: { x: '0', opacity: 1, 
                                    transition: { delay: 1.25, duration: 1.4, type: 'spring', damping: 25, stiffness: 300, } 
                            },
    }

    const delaySlideUp = {  hidden: { y: '100vh', opacity: '0', }, 
                            visible: {  y: '0', opacity: 1, 
                                        transition: { delay: 2.2, duration: 1.4, type: 'spring', damping: 25, stiffness: 400, }
                            },
    }
    
    return {
            zoomIn,
            delayFromRight,
            delayFromLeft,
            delaySlideUp,
            zoomText,
            slideUp,
            slideDown,
            slideFromRight,
            slideFromLeft,
        }
    }