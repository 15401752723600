import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Typography, Box, Stack } from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import SignUpWithEmailEntryForm from '../components/forms/SignUpWithEmailEntryForm';
import SignInWithEmailAndPasswordForm from '../components/forms/SignInWithEmailAndPasswordForm';
import VideoDemoCard from '../components/cards/VideoDemoCard';
import { colorPackTwo } from '../theme/theme';
import ImgCardwithText from '../components/cards/ImgCardwithText';
import { imgCardData } from '../data/imgCardData';
import ContinueWithGoogle from '../components/buttons/ContinueWithGoogle';
import HeaderCard from '../components/cards/HeaderCard';

const rootStyle = {
	//must define style b/c gradient background will not paint correclty with inline styling
	display: 'flex',
	flexFlow: 'column noWrap',
	alignItems: 'center',
	justifyContent: 'center',
	background: `linear-gradient(145deg, rgba(122,0,64,1) 0%, rgba(161,3,85,1) 47%, rgba(214,77,148,1) 100%)`,
	width: '100vw',
	height: '100%',
};

const pStyle = {
	fontStyle: 'italic',
	letterSpacing: 0,
	textAlign: 'center',
	lineHeight: 1.5,
	color: 'white',
	display: 'flex',
	flexFlow: 'column nowrap',
	justifyContent: 'stretch',
	alignItems: 'center',
};

const signInFormStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	width: 320,
	height: 420,
	transform: 'translate(-50%, -50%)',
	zIndex: 10,
};

export default function LoginPage() {
	const navigate = useNavigate();
	
	const { login } = useContext(AuthContext);
	const { googleSignIn, validateToken } = useAuth();

	const [showEmailForm, setShowEmailForm] = useState(false);
	const [showSignInForm, setShowSignInForm] = useState(false);
	const { secondaryDark } = colorPackTwo;
	const { img1, img2, img3 } = imgCardData;

	const handleClickPrivacy = () => {
		navigate('privacy');
	};

	const closeModal = () => {
		setTimeout(() => {
			setShowEmailForm(false);
			setShowSignInForm(false);
		}, 500);
	};
	const handleGoogleSignIn = () => {
		googleSignIn();
	};

	const validateSession = () => {
		let isSession = validateToken();
		if (isSession) {
			login();
			navigate('/');
		}
	};

	const handleEmailSignIn = () => {
		setShowSignInForm(true);
	};

	useEffect(() => {
		validateSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div style={rootStyle}>
			<Stack
				gap={8}
				sx={{
					display: 'flex',
					flexFlow: 'column noWrap',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{showSignInForm && (
					<Box sx={{
                        position: 'fixed', // Changed to fixed
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 10,
                        width: 320, // Adjust size as needed
                        maxWidth: '90%', // Ensure it doesn't overflow on small screens
                    }}>

					<div style={signInFormStyle}>
						<SignInWithEmailAndPasswordForm closeModal={closeModal} />
					</div>
					</Box>
				)}
				{showEmailForm && (
					<div style={signInFormStyle}>
						<SignUpWithEmailEntryForm closeModal={closeModal} />
					</div>
				)}

				<HeaderCard />

				<Box
					sx={{
						display: 'flex',
						flexFlow: 'column nowrap',
						justifySelf: 'stretch',
						alignItems: 'center',
						maxWidth: '100%',
						backgroundColor: 'white',
						p: 1,
					}}
				>
					<VideoDemoCard />
				</Box>

				<ContinueWithGoogle onClick={handleGoogleSignIn} />

				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<ImgCardwithText txt={img1.txt} url={img1.url} />
				</Box>

				<ContinueWithGoogle onClick={handleGoogleSignIn} />

				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<ImgCardwithText txt={img2.txt} url={img2.url} />
				</Box>

				<ContinueWithGoogle onClick={handleGoogleSignIn} />

				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<ImgCardwithText txt={img3.txt} url={img3.url} />
				</Box>

				<ContinueWithGoogle onClick={handleGoogleSignIn} />

				<Typography
					style={pStyle}
					sx={{ p: 1, textAlign: 'center', mt: 2, mb: 6, color: 'white' }}
				>
					A limited number of early-access accounts are available now. Continue
					with Google to get your first 5GBs of storage free.
				</Typography>

				<div style={{ marginTop: 8, marginBottom: 24, textAlign: 'center' }}>
					<Box>
						<Typography
							onClick={handleClickPrivacy}
							variant="body2"
							color={secondaryDark}
						>
							Privacy Policy
						</Typography>
					</Box>
					<Typography
						variant="subtitle2"
						onClick={handleEmailSignIn}
						label={'sign in with Email'}
					>
						Developer Login
					</Typography>
				</div>

				<Box sx={{ pb: 4 }} />
			</Stack>
		</div>
	);
}
