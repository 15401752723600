import React, { useState } from 'react';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined'; //favourite == false 
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'; //favourite == true 
import { colorPack } from '../../theme/theme';

export default function FavouritesIcon({favourite, onClick}) {
    const { primaryMain, lightText } = colorPack;
    const [ isFavourite, setIsFavourite ] = useState(() => favourite);

    const iconStyle = {
        color: lightText,
    }
    
    const iconFavStyle = {
        color: primaryMain,
    }

    const handleClick = () => {
        setIsFavourite(!isFavourite);
         //update favourite status in firebase with useFavourites hook
        onClick(); 
    }

    return (
        <>
            {isFavourite? (
                <LocalFireDepartmentIcon onClick={handleClick} style={iconFavStyle} />
            ):(
                <LocalFireDepartmentOutlinedIcon onClick={handleClick} style={iconStyle} />
            )}
        </>
    )
}
