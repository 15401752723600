import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Paper } from '@mui/material';
import { colorPack } from '../theme/theme';

const { secondaryDark } = colorPack; 

const paperStyle = {
  backgroundColor: secondaryDark,
  justifyContent: 'stretch',
  alignItems: 'center',
  maxWidth: 380,
  padding: 8, 
  margin: 'auto',
  marginTop: 120, 
}

export default function ErrorPage() {
  const navigate = useNavigate(); 
  
  const handleClickHome = () => {
    navigate('/');
  }
  
  return (
    <Paper style={paperStyle} onClick={handleClickHome} elevation={6}>
      <Typography variant='h6' component="div">
          <span data-testid='errorMsg'>Hmmm, we can't seem to find that page. Let's head </span>
            <span style={{color: 'white'}} onClick={handleClickHome}>Back Home</span> and try again.
      </Typography>
    </Paper>
  );
}
