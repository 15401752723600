import React from 'react';
import { useForm } from 'react-hook-form';
import { Paper, Stack } from '@mui/material';
import FormInputText from './formComponents/FormInputText';
import FormInputTextArea from './formComponents/FormInputTextArea';
import PasswordInputText from './formComponents/PasswordInputText';
import { colorPack } from '../../theme/theme';
import ButtonMain from '../buttons/ButtonMain';
import { motion } from 'framer-motion';
import { useAuth } from '../../hooks/useAuth';
import { useMotion } from '../../hooks/useMotion';

const { secondaryMain, primaryLight } = colorPack;

const paperStyle = {
	display: 'flex',
	flexFlow: 'column noWrap',
	alignItems: 'stretch',
	justifyContent: 'center',
	padding: 8,
	backgroundColor: primaryLight,
};

export default function SignInWithEmailAndPasswordForm({ closeModal }) {
	const { zoomIn } = useMotion();
	const { signInWithEmailPassword } = useAuth();
	const emptySignIn = {
		email: '',
		password: '',
	};

	const { handleSubmit, control } = useForm({ defaultValues: emptySignIn });

	const onSubmit = () => {
		handleSubmit((formData) => {
			const formResult = Object.assign(formData);
			signInWithEmailPassword(formResult);
			closeModal();
		})();
	};

	return (
		<motion.div
			style={{ padding: 0 }}
			variants={zoomIn}
			initial="hidden"
			animate="visible"
			exit="exit"
		>
			<Paper style={paperStyle}>
				<Stack gap={2}>
					<FormInputText
						style={{ backgroundColor: 'white' }}
						name={'email'}
						control={control}
						type={'email'}
						label={'email'}
					/>
					<PasswordInputText
						name={'password'}
						control={control}
						type="password"
						label={'password'}
					/>
					<ButtonMain
						type="submit"
						onClick={handleSubmit(onSubmit)}
						width={'100%'}
						label={'Submit'}
						variant="contained"
						bgColor={secondaryMain}
						color="white"
						text="Submit Request"
					/>
					<div style={{ position: 'relative', marginLeft: 'auto' }}>
						<ButtonMain
							onClick={closeModal}
							label={'Cancel'}
							bgColor={'white'}
							width={'25%'}
						/>
					</div>
				</Stack>
			</Paper>
		</motion.div>
	);
}
