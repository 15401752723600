import React from 'react';
import { useForm } from "react-hook-form";
import useQuote from '../../hooks/useQuote';
import { useParams } from 'react-router-dom';
import { Paper, Stack } from '@mui/material'; 
import FormInputText from "./formComponents/FormInputText";
import FormInputTextArea from './formComponents/FormInputTextArea';
import { colorPack } from '../../theme/theme';
import ButtonMain from '../buttons/ButtonMain';
import { motion } from 'framer-motion';
import { useMotion } from '../../hooks/useMotion';

export default function QuoteForm({closeModal}) {
    const { secondaryMain, primaryLight } = colorPack;
    const { addQuote } = useQuote(); 
    const { capsuleName } = useParams(); 
    const { zoomIn } = useMotion();
    
    const handleBlur = (e) => {
        return e.preventDefault(); 
    }

    const emptyQuoteEntry = {
        quote: '',
        author: '',
        context: '',
        date: '',
        favourite: false,
    }

    const { handleSubmit, control } = useForm({ defaultValues: emptyQuoteEntry});
    
    const onSubmit = () => {
        handleSubmit((formData) => {
            const formResult = Object.assign(formData, {favourite: false});
            console.log("FORM DATA IS: ", formResult);
            addQuote(formData, capsuleName);
            setTimeout(() => {
                closeModal();
            }, 1000);
        })();
    };

return (
        <motion.div
            style={{padding: 0}}
            variants={zoomIn}
            initial='hidden'
            animate='visible' 
            exit='exit'
        >
            <Paper elevation={0} style={{backgroundColor:primaryLight, padding: 8}}>
                <Stack gap={2}>
                    <FormInputText
                        style={{backgroundColor: 'white'}} 
                        label={"Quote"} 
                        name={'quote'} 
                        control={control}
                        onBlur={handleBlur}
                    />
                    <FormInputText 
                        style={{backgroundColor: 'white'}} 
                        label={"Author"}
                        name={'author'} 
                        control={control} 
                        onBlur={handleBlur}
                    />
                    <FormInputTextArea 
                        name={'context'} 
                        label={"Context"}
                        control={control} 
                        onBlur={handleBlur}
                    />
                    <FormInputText 
                        style={{backgroundColor: 'white'}} 
                        label={"Date"}
                        name={'date'} 
                        control={control} 
                        onBlur={handleBlur}
                    />
                    <ButtonMain 
                        type='submit' 
                        onBlur={handleBlur}
                        onClick={handleSubmit(onSubmit)}
                        width={'100%'} 
                        label={'Submit'} 
                        variant='contained' 
                        bgColor={secondaryMain} 
                        color='white' 
                        text="Submit Request" 
                    />
                </Stack>
            </Paper>
    </motion.div>
    )
}
