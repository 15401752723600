import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const loaderStyle = {
  display: 'flex',
  flexFlow: 'column noWrap',
  alignItems: 'center',
  justifyContent: 'stretch',
  width: '340',
  margin: 'auto',
  marginTop: 120, 
}

export default function AsyncLoadingCard() {
    return (
        <div style={loaderStyle}>
           <CircularProgress color="secondary" />
        </div>
    );
}