import heic2any from 'heic2any';

// Utility function for HEIC to JPG conversion
const convertHeicToJpg = async (file) => {
  try {
    const convertedFile = await heic2any({
      blob: file,
      toType: 'image/jpeg',
    });
    // Assign a new name with the .jpg extension
    convertedFile.name = file.name.substring(0, file.name.lastIndexOf('.')) + '.jpg';
    return convertedFile;
  } catch (error) {
    console.error('Error converting HEIC file:', error);
    throw new Error('Failed to convert HEIC file.');
  }
};

// Check if the file extension is in the list of valid extensions
const isValidExtension = (filename, validExtensions) => {
  const extension = filename.split('.').pop().toLowerCase();
  return validExtensions.includes(extension);
};

const useMediaConverter = () => {

	/**
	 * function will check if user selected files are compatible and convert HEIC files to jpg where required
	 * @param {array} fileUpload user selected image and video files for upload
	 * @returns an array containing browswer compatible files for upload to storage and firebase db
	 */
  const validExtensions = ['jpg', 'jpeg', 'png', 'mpeg', 'mpg', 'mp4', 'mov'];
  const heicExtensions = ['heic', 'heif'];

  const validateAndConvertFiles = async (files) => {
    const processedFiles = [];
    for (const file of files) {
      const filename = file.name;
      if (isValidExtension(filename, validExtensions)) {
        processedFiles.push(file);
      } else if (isValidExtension(filename, heicExtensions)) {
        try {
          const convertedFile = await convertHeicToJpg(file);
          processedFiles.push(convertedFile);
        } catch (error) {
          console.error('Error processing file:', error);
        }
      }
    }
    return processedFiles;
  };

  const validateCoverPhoto = async (file) => {
    try {
      const processedFiles = await validateAndConvertFiles([file]);
      return processedFiles.length > 0 ? processedFiles[0] : false;
    } catch (error) {
      console.error('Error validating cover photo:', error);
      return false;
}	
  };

  return {
    validateAndConvertFiles,
    validateCoverPhoto,
  };
};

export default useMediaConverter;
