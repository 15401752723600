import { useContext, useCallback, useState } from 'react';
import { db } from '../services/firebase';
import { serverTimestamp, addDoc, query, collection, onSnapshot } from 'firebase/firestore';
import { format } from 'date-fns'; 
import { AuthContext } from '../context/AuthProvider';

export const useJournal = () => {
    const authContext = useContext(AuthContext);
    const uid = authContext.uid;
    const [ journalEntries, setJournalEntries ] = useState([]);
    
    /**
     * will add new journal entry to the target capsule and format the timestamp to be user friendly
     * @param {Object} formData - user form input result
     * @param {string} capsuleName - name of the target capsule
     */
    const addJournalEntry = async (capsuleName, formData) => {
        const { entry, title } = formData; 
        const journalRef = collection(db, `users/${uid}/capsules/${capsuleName}/journal`);
        const currentDate = new Date(); 
        const entryDate = format(currentDate, 'yyyy-MM-dd'); 
        addDoc(journalRef, {
            date: entryDate,
            capsuleName: capsuleName,
            title: title,
            entry: entry,
            timeStamp: serverTimestamp(),
            favourite: false,
        })
        .catch((error) => {
            console.log("Error adding Journal entry.  Error: ", error);
        })
    }

    /**
     * is a realtime database connection to fetch user's journal entries 
     * @param {string} capsuleName - name of the target document
     * @returns an array of document data (user's journal entries) to be consumed by the U/I
     */
    const fetchAllJournalEntries = useCallback((capsuleName) => {
        (function getAllEntries(){
            const path = `users/${uid}/capsules/${capsuleName}/journal`;
            const colRef = collection(db, path);
            const qSub = query(colRef);
            onSnapshot(qSub, (snapData) => {
                const snap = snapData?.docs.map( doc => ({...doc.data(), id: doc.id}));
                setJournalEntries(snap);
                console.log("Current quotes are: ", snap);
                if(snap?.length > 0){
                    setJournalEntries(snap);
                } else {
                    setJournalEntries(null);
                }
            });
        })();
    },[uid]); 


return {
        journalEntries,
        fetchAllJournalEntries,
        addJournalEntry,
    }
}
