import React from 'react';
import { Box, Modal } from '@mui/material';
import UploadCoverPhotoForm from './UploadCoverPhotoForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 440,
  p: 1, 
};

export default function UploadCoverPhotoFormModal({closeModal, isOpen, capsuleBtnName}) {
    const handleClose = () => closeModal();

  return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            onBlur={(e) => e.preventDefault()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <UploadCoverPhotoForm
                closeModal={handleClose}
                capsuleBtnName={capsuleBtnName} 
                style={{zIndex: 100}}
            /> 
        </Box>
      </Modal>
  );
}