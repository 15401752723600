import React from 'react';

const prevImgStyle = {
    width: '100%',
    aspectRatio: 'auto',
    maxHeight: '60vh',
}

export default function PreviewImgCard({src}) {
    return (
            <div width='100vw'>
                <img src={src} style={prevImgStyle}   alt='uploaded' /> 
            </div>
    )
}
