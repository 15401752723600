import React, { useContext } from 'react';
import { UserPrefsContext } from '../../context/UserPrefsProvider';
import { useSettings } from '../../hooks/useSettings';
import SchoolIcon from '@mui/icons-material/School';
import { colorPackTwo } from '../../theme/theme';

export default function TrainingHelpButton() {
	const { toggleTrainingVisible } = useSettings(); 
	const { newUserTrainingVisible, toggleNewUserTrainingVisible } =
		useContext(UserPrefsContext);
	const { secondaryLight } = colorPackTwo;

	const handleTrainingBtnClick = () => {
		toggleTrainingVisible() //updates training preference in firebase 
		.then(() => toggleNewUserTrainingVisible()) // updates training preference in context
		.catch((error) => console.log("Error toggling training status. Error: ", error))
		.finally(console.log("Success handling training click"));
	};

	return (
		<> 
			{newUserTrainingVisible ? (
				<SchoolIcon
					onClick={handleTrainingBtnClick}
					variant="body2"
					sx={{ color: secondaryLight }}
				/>
			) : (
				<SchoolIcon
					onClick={handleTrainingBtnClick}
					variant="body2"
					sx={{ color: 'white' }}
				/>
			)}
		</>
	);
}
