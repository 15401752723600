import { useReducer, createContext } from "react";

const AUTH_STATE = {
    isLoggedIn: false,
    uid: '',
    user: {}, 
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
          ...state,
          isLoggedIn: true 
      };
    case "UID":
      return {
        ...state,
        uid: action.payload,
      };
    case "USER":
        return {
          ...state,
          user: action.payload,
        };
    case "LOGOUT":
          window.sessionStorage.clear();
      return {
          isLoggedIn: false 
      };
    default:
      return AUTH_STATE;
  }
};


export const AuthContext = createContext();
AuthContext.displayName = "AuthContext";

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, AUTH_STATE);
    const value = {
        ...state,
        login: () => {
            dispatch({ type: "LOGIN" });
        },
        logout: () => {
            dispatch({ type: "LOGOUT" });
        },
        storeUid: (uid) => {
            dispatch({ type: "UID", payload: uid})
        },
        storeUser: (User) => {
          dispatch({ type: "USER", payload: User})
      },
    };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

