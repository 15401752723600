import React from 'react';
import MenuBarTrainingSideNav from './MenuBarTrainingSideNav';
import { motion } from 'framer-motion';
import { useMotion } from '../../hooks/useMotion';
import { Stack, Paper, Typography } from '@mui/material';

export default function RightNavTrainingCard() {
	const { delayFromRight } = useMotion();

	return (
		<Stack>
			<Paper
				elevation={9}
				sx={{
					backgroundColor: 'secondary.light',
					color: 'secondary.dark',
					textAlign: 'center',
					position: 'absolute',
					right: 20,
					mt: 13,
					p: 1,
					width: '90vw',
				}}
			>
				<Typography variant="body1">
					Each capsule has four sections to keep you organized.
					<Typography
						variant="subtitle1"
						color="primary.main"
						component="span"
						sx={{ fontStyle: 'italic', mr: 1 }}
					>
						Journal Entries, Quotes, Special Items
					</Typography>
					(Pictures with Text Descriptions), and
					<Typography
						variant="subtitle1"
						color="primary.main"
						component="span"
						sx={{ fontStyle: 'italic', ml: 1 }}
					>
						Pictures/Videos.
					</Typography>
				</Typography>
			</Paper>
			<motion.div
				style={{ position: 'fixed', bottom: 70, right: 70 }}
				variants={delayFromRight}
				initial="hidden"
				animate="visible"
				exit="exit"
			>
				<MenuBarTrainingSideNav />
			</motion.div>
		</Stack>
	);
}
