
//count number of words in name
const splitWords = (name) => {
    return name.split(" "); 
}

//reduce the name length to a max of 3 words
const reduceWordCount = (titleArray) => {
    const length = titleArray.length; 
    const shortTitle = titleArray?.splice(0,3);
    const formattedTitle = shortTitle.map( str => formatStr(str));
    const multiLineTitle = lineBreaks(formattedTitle);
    if(length > 3){
        const longTitle = multiLineTitle + '...';
        return longTitle; 
    }
        return multiLineTitle
}

// returns a string with a max length of 8 chars & adds '...' if > 8
const formatStr = (str) => {
    if(str?.length > 8){
        const newStr = str.slice(0,8);
        return newStr + '...';  
    }
        return str; 
}        

//puts each word in capsule name on a new line
const lineBreaks = (title) => {
    const titleStr = title.join(" ");
    return titleStr.replaceAll(" ", "\n");
}  

/**
 * will format the capsule name to provide a consistent look (used for the Dashboard's capsuleButtons)
 * eg.  If a capsule is called It's Do THIIIIIIIIIIIS... the name would display as 
 * 
 * '    Let's Do 
 *      THIIIIIIII...
 * ' 
 * @param {string} capsuleName - name of the capsule being formatted for display 
 * @returns 
 */
export const formatName = (capsuleName) => {
    const wordCount = splitWords(capsuleName);
    if(wordCount?.length === 1){
        return formatStr(capsuleName); 
    } else {
        const newTitle = reduceWordCount(wordCount); 
        return newTitle;
    }
}

export const getLinkRoute = (capsuleName, subCapsuleName) => {
    if(subCapsuleName === undefined){
        return `/${capsuleName}/media`;
    } 
    if(subCapsuleName !== undefined){
        return `/${capsuleName}/${subCapsuleName}`;
    }
}