import React from 'react';
import QuoteCard from '../cards/QuoteCard'; 
import { Box, Paper } from '@mui/material';
import { colorPackTwo } from '../../theme/theme';
import { useParams } from 'react-router';
 
export default function QuoteTile({quoteData}) {
    const { primaryLight } = colorPackTwo;
    const { capsuleName } = useParams(); 
        
return (
    <>
        {quoteData?.length > 0 && 
            <Box sx={{
                display: 'flex', 
                flexFlow: 'column noWrap', 
                justifyContent: 'center',  
                alignItems: 'stretch',
                mt:2,
            }}> 
                <Paper elevation={0} style={{padding:0}}>
                    {quoteData?.map((doc, index) => {
                        return (
                            <Paper 
                                key={doc.id}
                                elevation={0} 
                                style={{
                                    backgroundColor: primaryLight, 
                                    marginTop: 8, 
                                    padding: 4
                                }} 
                            >
                                <QuoteCard 
                                    index={index + 1}
                                    id={doc.id}
                                    quote={doc.quote}
                                    context={doc.context}
                                    author={doc.author}
                                    date={doc.date}
                                    favourite={doc.favourite}
                                    capsuleName={capsuleName}
                                />
                            </Paper>
                        );
                    })}
                </Paper>
            </Box>
        }
    </>
  )
}