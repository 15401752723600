import React from 'react';
import { Box, Modal } from '@mui/material';
import UploadItemForm from './UploadItemForm';

const boxStyle = {
    display: 'flex',
    flexFlow: 'column noWrap',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%vw',
};

const modalStyle = {
    display: 'flex',
    flexFlow: 'column noWrap',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%vw',
}

export default function UploadItemFormModal({closeModal, isOpen}) {
    const handleClose = () => {
        setTimeout(() => {
            closeModal(); 
        }, 1000);
    }
    return (
        <Modal
            sx={{overflowY: 'scroll'}} 
            disableScrollLock={false}
            style={modalStyle}
            onBlur={(e) => e.preventDefault()}
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box style={boxStyle} component='form'>
            <UploadItemForm closeModal={handleClose} />
        </Box>
      </Modal>
  );
} 