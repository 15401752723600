import { useContext, useState } from 'react';
import {
	getStorage,
	getMetadata,
	ref,
	uploadBytesResumable,
	getDownloadURL,
} from 'firebase/storage';
import {
	doc,
	setDoc,
	arrayUnion,
	arrayRemove,
	updateDoc,
} from 'firebase/firestore';
import { app, db } from '../services/firebase';
import { useFavourite } from './useFavourite';
import { AuthContext } from '../context/AuthProvider';

export const useStorage = () => {
	const authContext = useContext(AuthContext);
	const uid = authContext.uid;
	const { toggleAllFavourites } = useFavourite();

	const [progresspercent, setProgresspercent] = useState(0);
	const [uploadCount, setUploadCount] = useState(0);
	const [ttlUploadCount, setTtlUploadCount] = useState(0);

	const rootPath = `users/${uid}/capsules`;

	/**
	 * will add a new object to the target capsule's media array
	 * @param {string} url - download url of the media file in Storage
	 * @param {Boolean} favourite - current favourite status
	 * @param {string} fileName - name of the media file
	 */
	const addUrlToMediaArray = async (
		url,
		favourite,
		fileName,
		capsuleName,
		subCapsuleName
	) => {
		let path = `${rootPath}/${capsuleName}`;
		let capsuleRef;
		subCapsuleName?.length > 0
			? (capsuleRef = doc(db, `${path}/subCapsules/${subCapsuleName}`))
			: (capsuleRef = doc(db, path));
		let options = { url, favourite, fileName, capsuleName };
		subCapsuleName?.length > 0 && (options = { ...options, subCapsuleName });
		await setDoc(
			capsuleRef,
			{
				media: arrayUnion(options),
			},
			{ merge: true }
		).catch((error) =>
			console.log('Error adding file to capsule array. Error: ', error)
		);
	};

	/**
	 * will permanently destroy a media file and remove from allFavourites if applicable
	 * @param {Object} options - media file key/values to exactly match against media array
	 */
	const destroyMediaFile = async (options) => {
		// eslint-disable-next-line no-unused-vars
		const { url, favourite, capsuleName, fileName, subCapsuleName } = options;
		if (favourite) {
			let cardType = 'imgCard';
			await toggleAllFavourites(options, favourite, cardType);
		}
		let path = `${rootPath}/${capsuleName}`;
		let mediaObj = options;
		if (subCapsuleName?.length > 0) {
			path = `${rootPath}/${capsuleName}/subCapsules/${subCapsuleName}`;
			mediaObj = { ...mediaObj, subCapsuleName };
		}
		const mediaRef = doc(db, path);
		await updateDoc(mediaRef, {
			media: arrayRemove(
				{
					...mediaObj,
					favourite: favourite,
				},
				{ merge: true }
			),
		}).catch((error) =>
			console.log('Error destroying media file. Error: ', error)
		);
	};

	/**
	 * uploads media files (img/vid) to the user's media bucket in Storage,
	 * then adds a new obj to the capsule's media array with the downloadURL to be consumed by capusules
	 * @param {Object} files - array of user selected media files for upload (img | vid)
	 * @param {string} capsuleName - name of the target capsule
	 * @param {?string} subCapsuleName - name of the subCapsule name if applicable
	 * @returns a promise which resolves if the media is uploaded to Storage successfully && firebase DB is updated
	 */
	const addMediaToStorage = async (
		files,
		capsuleName,
		subCapsuleName,
		closeModal
	) => {
		const storage = getStorage(app);
		const mediaArr = Array.from(files || []);
		let fileCount = mediaArr?.length;
		setTtlUploadCount(fileCount);
		let fileArray = [];
		let mediaFile;
		let fileName;
		for (let i = 0; i < mediaArr.length; i++) {
			let media = mediaArr[i];
			fileName = media.name;
			mediaFile = media;
			const metadata = {
				fileName: fileName,
				capsuleName: capsuleName,
				subCapsuleName: subCapsuleName || 'none',
			};
			const mediaPath = `users/${uid}/media/${fileName}`;
			const mediaRef = ref(storage, mediaPath);
			const uploadTask = uploadBytesResumable(mediaRef, mediaFile, metadata);
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					const progress = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					);
					setProgresspercent(progress);
				},
				(error) => {
					alert(error);
				},
				// eslint-disable-next-line no-loop-func
				async () => {
					await getDownloadURL(mediaRef)
						.then((url) => {
							let favourite = false;
							subCapsuleName?.length > 0
								? addUrlToMediaArray(
										url,
										favourite,
										fileName,
										capsuleName,
										subCapsuleName
								  )
								: addUrlToMediaArray(url, favourite, fileName, capsuleName);
							fileArray.push(url);
							return setUploadCount(fileArray?.length);
						})
						.then(() => {
							fileCount === fileArray?.length && closeModal();
						})
						.catch((error) =>
							console.log('ERROR retrieving downloadUrl. Error: ', error)
						);
				}
			);
		}
	};

	/**
	 * will retrieve a media file's metadata information from Storage
	 * @param {string} fileName - name of the media file
	 * @returns an Object containing the file's metadata (eg. customMedaData: {capsuleName: Loren, subCapsuleName: Birthdays, fileName: cake.jpg})
	 */
	const getFileMetaData = async (fileName) => {
		const storage = getStorage(app);
		const fileRef = ref(storage, `users/${uid}/media/${fileName}`);
		try {
			return await getMetadata(fileRef);
		} catch (error) {
			console.log('Error fetching metadata.  Error: ', error);
		}
	};

	/**
	 * adds/updates a capsule's coverPhoto field. Used as the background image of the capsule buttons on a user's dashboard
	 * @param {string} url - downloadURL of the cover photo from Storage
	 * @param {string} capsuleName - name of the target document
	 */
	const editCoverPhoto = async (url, capsuleName) => {
		let capsuleRef = doc(db, `${rootPath}/${capsuleName}`);
		await setDoc(
			capsuleRef,
			{
				coverPhoto: {
					url: url,
				},
			},
			{ merge: true }
		);
	};

	return {
		destroyMediaFile,
		getFileMetaData,
		addMediaToStorage,
		progresspercent,
		editCoverPhoto,
		uploadCount,
		ttlUploadCount,
	};
};

export default useStorage;
