import React from 'react';
import { Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';

export default function ButtonMain({label, variant, bgColor, color, onClick, width, startIcon}) {

    const btnStyle = {
        backgroundColor: bgColor, 
        color: color, 
        height: 36, 
        paddingLeft: 8, 
        paddingRight: 8, 
        width: width
    }
 
    return (
            <Typography variant='button' component='span'>
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.8 }}
                >
                    <Button 
                        style={btnStyle} 
                        label={label} 
                        onClick={onClick} 
                        variant={variant}
                        startIcon={startIcon}
                    >
                        {label}
                    </Button>
                </motion.div>
            </Typography> 
    )
}
