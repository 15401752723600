import { useState, useCallback, useContext } from 'react';
import { getDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from '../services/firebase';
import { AuthContext } from '../context/AuthProvider';

export const useMedia = () => { 
    const [ capsuleMedia, setCapsuleMedia ] = useState([]);  
    const [ subCapsuleMedia, setSubCapsuleMedia ] = useState([]);
    const authContext = useContext(AuthContext);
    const uid = authContext.uid;
  
    /**
     * @async will fetch media from target capsule
     * @param {string} capsuleName - name of the target capsule  
     */
    const fetchCapsuleMedia = useCallback((capsuleName) => {
            (async function getCapsuleMedia(){
                try {
                    const docRef = doc(db, `users/${uid}/capsules/${capsuleName}`)
                    const docSnap = await getDoc(docRef);
                        if(docSnap.exists()){
                            const mediaData = docSnap.data()?.media;
                            mediaData?.length > 0 && setCapsuleMedia(mediaData);
                            mediaData?.length === 0 && setCapsuleMedia(null);
                        }
                    } catch (error) {
                    console.log("Error fetching Capsule Media. Error: ", error);
                }
            })(); 
    },[uid]); 

    function capsuleMediaListener(capsuleName){
            const unsubscribe = onSnapshot(doc(db, `users/${uid}/capsules/${capsuleName}`), (doc) => {
            const media = doc.data()?.media;
            (media !== undefined && media?.length > 0) && setCapsuleMedia(media);
            });
        return unsubscribe; 
    }

    /**
     * @async will fetch the media from the subCapsule
     * @param {string} capsuleName - name of the target capsule
     * @param {string} subCapsuleName - name of the target subCapsule
     */
    const fetchSubCapsuleMedia = async (capsuleName, subCapsuleName) => {
        try {
            const docRef = doc(db, `users/${uid}/capsules/${capsuleName}/subCapsules/${subCapsuleName}`);
            const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const mediaData = docSnap.data()?.media;
                    mediaData?.length > 0 && setCapsuleMedia(mediaData);
                    mediaData?.length === 0 && setCapsuleMedia(null);
                    return setSubCapsuleMedia(mediaData);
                }
            } catch (error) {
                console.log("Error fetching subCapsule Media. Error: ", error);
        }
    }      
    
    return{
            capsuleMediaListener,
            fetchCapsuleMedia,
            capsuleMedia,
            fetchSubCapsuleMedia,
            subCapsuleMedia,
    }
}

export default useMedia;
