import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Paper } from '@mui/material';
import JournalTile from '../components/tiles/JournalTile';
import CursiveTitleCard from '../components/cards/CursiveTitleCard';
import JournalEntryFormModal from '../components/forms/JournalEntryFormModal';
import FabButton from '../components/buttons/FabButton';
import { useJournal } from '../hooks/useJournal';
import { useAsync } from '../hooks/useAsync';
import FabTrainingCard from '../components/Training/FabTrainingCard';
import { themeBreakPoints } from '../theme/theme';
import { UserPrefsContext } from '../context/UserPrefsProvider.js';

export default function JournalPage() {
	const [showJournalEntryForm, setShowJournalEntryForm] = useState(false);
	const [journalData, setJournalData] = useState(undefined);
	const { journalEntries, fetchAllJournalEntries } = useJournal();
	const { capsuleName } = useParams();
	const { newUserTrainingVisible } = useContext(UserPrefsContext);
	const [showTraining, setShowTraining] = useState(false);

	const [asyncState, setAsyncState] = useState('');
	const [showLoader, setShowLoader] = useState(false);
	const loadingState = useAsync(asyncState);

	const handleCloseModal = () => setShowJournalEntryForm(false);

	useEffect(() => {
		setAsyncState('pending');
		setShowLoader(true);
		fetchAllJournalEntries(capsuleName);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchAllJournalEntries]);

	useEffect(() => {
		if (journalEntries !== null && journalEntries?.length > 0) {
			setAsyncState('success');
			setShowLoader(false);
			setJournalData(journalEntries);
		} else {
			setJournalData([]);
			setShowLoader(false);
			setAsyncState('success');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [journalEntries]);
    useEffect(() => {
        newUserTrainingVisible && setShowTraining(true);
        !newUserTrainingVisible && setShowTraining(false);
    }, [newUserTrainingVisible]);

	return (
		<Stack
			direction="column"
			gap="2"
			alignItems="stretch"
			justifyContent="center"
		>
			<Paper elevation={0} sx={{ maxWidth: themeBreakPoints, p: 1 }}>
				<CursiveTitleCard title={`${capsuleName} Journals`} />
				{showLoader && loadingState}
				<JournalEntryFormModal
					isOpen={showJournalEntryForm}
					closeModal={handleCloseModal}
				/>
				{showTraining && journalData?.length === 0 && (
					<FabTrainingCard message={'Click To Add A Journal Entry'} />
				)}
				<JournalTile journalData={journalData} />
				<FabButton
					onClick={() => setShowJournalEntryForm(true)}
					style={{ backgroundColor: 'red' }}
					size="small"
					aria-label="add"
				/>
			</Paper>
		</Stack>
	);
}
