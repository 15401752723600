import React from 'react';
import { Box, Modal } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'100%',
    maxWidth: 400,
    padding: 1,
  };

export default function ModalMain({modalElement, closeModal, isOpen}) {
  const handleClose = () => closeModal();

  return (
        <Modal
            open={isOpen}
            onBlur={(e) => e.preventDefault()}
            onClose={handleClose}
            aria-labelledby="new quote form"
            aria-describedby="upload a new quote"
        >
        <Box sx={style}>
            {modalElement}
        </Box>
      </Modal>
  );
}