import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Paper } from '@mui/material';
import UploadItemFormModal from '../components/forms/UploadItemFormModal';
import ItemsTile from '../components/tiles/ItemsTile';
import CursiveTitleCard from '../components/cards/CursiveTitleCard';
import FabButton from '../components/buttons/FabButton';
import { AuthContext } from '../context/AuthProvider';
import { useAsync } from '../hooks/useAsync';
import { useItem } from '../hooks/useItem';
import FabTrainingCard from '../components/Training/FabTrainingCard';
import { themeBreakPoints } from '../theme/theme';
import { UserPrefsContext } from '../context/UserPrefsProvider.js';

export default function ItemPage() {
	const { uid } = useContext(AuthContext);
	const { capsuleName } = useParams();
	const { userItems, fetchAllItems } = useItem();
	const [showUploadItemForm, setShowUploadItemForm] = useState(false);
	const [itemData, setItemData] = useState([]);
	const [asyncState, setAsyncState] = useState('');
	const [showLoader, setShowLoader] = useState(false);
	const loadingState = useAsync(asyncState);
	const [showTraining, setShowTraining] = useState(false);
	const { newUserTrainingVisible } = useContext(UserPrefsContext);

	const handleCloseModal = () => setShowUploadItemForm(false);

	useEffect(() => {
		setAsyncState('pending');
		setShowLoader(true);
		fetchAllItems(capsuleName);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchAllItems]);

	useEffect(() => {
		if (userItems !== null && userItems?.length > 0) {
			setAsyncState('success');
			setShowLoader(false);
			setItemData(userItems);
		} else {
			setItemData([]);
			setShowLoader(false);
			setAsyncState('success');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userItems, uid, fetchAllItems]);
	useEffect(() => {
        newUserTrainingVisible && setShowTraining(true);
        !newUserTrainingVisible && setShowTraining(false);
    }, [newUserTrainingVisible]);
	return (
		<Stack direction="row" gap="2" alignItems="center" justifyContent="center">
			<Paper elevation={0} sx={{ maxWidth: themeBreakPoints, p: 1 }}>
				<CursiveTitleCard title={`${capsuleName} Items`} />
				{showLoader && loadingState}
				{showTraining && itemData?.length === 0 && (
					<FabTrainingCard message={'Click To Add A New Item'} />
				)}
				<UploadItemFormModal
					isOpen={showUploadItemForm}
					closeModal={handleCloseModal}
				/>
				<ItemsTile itemData={itemData} />
				<FabButton
					onClick={() => setShowUploadItemForm(true)}
					style={{ backgroundColor: 'red' }}
					size="small"
					aria-label="add"
				/>
			</Paper>
		</Stack>
	);
}
