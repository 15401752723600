import React from 'react';
import { Controller } from "react-hook-form";
import { Input } from '@mui/material';
import { colorPackTwo } from '../../theme/theme';

export default function MediaFilePicker({ pullImageUrl, name, control}) {
    const { secondaryDark } = colorPackTwo;
return (
         <Controller
            name={name}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
                <Input
                    onChange={(e) => {
                        field.onChange({ 
                            target: { 
                                value: e.target.files[0], 
                                name: 'media' 
                            }
                        });
                            field.onBlur(pullImageUrl('/images/placeholder.png'));
                            const url = URL.createObjectURL(e.target.files[0]);
                            pullImageUrl(url);
                        }
                    }
                    
                    type='file'
                    style={{backgroundColor: 'white', padding: 8, color: secondaryDark}}
                    id='media'
                />
            )}
        />
    );
}
