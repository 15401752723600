import { useCallback, useContext, useState } from 'react';
import { db } from '../services/firebase';
import { query, onSnapshot, collection, getDoc, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { AuthContext } from '../context/AuthProvider';

export const useQuote = () => {
    const authContext = useContext(AuthContext);
    const [ quotes, setQuotes ] = useState([]);
    const uid = authContext.uid;
    
    /**
     * will add a new quote to the target capsule  
     * @param {Object} newQuote - the user input from the quote form
     * @param {string} capsuleName - name of the capsule where the quote lives
     */
    const addQuote = (newQuote, capsuleName) => {
        const collectionRef = collection(db, `users/${uid}/capsules/${capsuleName}/quotes`);
        addDoc(collectionRef, newQuote)
        .catch((error) => console.log("Error adding quote. Error: ", error));
    } 

    /**
     * will target and update a specific quote 
     * @param {string} id - the document id (path of the document) of the quote to be updated
     * @param {Object} newQuote - the document data with which to replace the existing quote 
     * @param {string} capsuleName - the name of the document (capsule) where the 'quotes' sub-collection is located 
     */
    const updateQuote = (id, newQuote, capsuleName) => {
        const collectionRef = collection(db, `users/${uid}/capsules/${capsuleName}/quotes`); 
        const docRef = doc(collectionRef, id);
        updateDoc(docRef, newQuote)
        .catch((error) => console.log("Error updating quote.  Error: ", error));
    }

    /**
     * will delete the quote associated with the id 
     * @param {string} id - the document id
     * @param {string} capsuleName - the name of the target capsule
     */

    const deleteQuote = (id, capsuleName) => {
        const collectionRef = collection(db, `users/${uid}/capsules/${capsuleName}/quotes`); 
        const docRef = doc(collectionRef, id);
        deleteDoc(docRef)
        .catch((error) => console.log("Error deleting quote. Error: ", error));
    }

    /**
     * will retrieve a specific quote based on the id
     * @param {string} id - the quote's document id 
     * @param {string} capsuleName - the target capsule name
     * @returns a document snapshot which can be referenced to update quotes with prefilled input elements 
     */

    const getQuote = (id, capsuleName) => {
        const collectionRef = collection(db, `users/${uid}/capsules/${capsuleName}/quotes`);
        const docRef = doc(collectionRef, id);
        return getDoc(docRef);
    } 
    
   /** 
     * * is a realtime database connection to fetch user's journal entries 
     * @param {string} capsuleName - the name of the target capsule  
     * @returns Promise that returns an array of the user's quotes when fulfilled 
     */

    const fetchAllQuotes = useCallback((capsuleName) => {
        (function getAllQuotes(){
            const path = `users/${uid}/capsules/${capsuleName}/quotes`;
            const colRef = collection(db, path);
            const qSub = query(colRef);
            onSnapshot(qSub, (snapData) => {
                const snap = snapData?.docs.map( doc => ({...doc.data(), id: doc.id}));
                setQuotes(snap);
                console.log("Current quotes are: ", snap);
                if(snap?.length > 0){
                    setQuotes(snap);
                } else {
                    setQuotes(null);
                }
            });
        })();
    },[uid]); 

    return {
        fetchAllQuotes,
        quotes,
        addQuote,
        updateQuote,
        deleteQuote,
        getQuote,
    }
}

export default useQuote; 
