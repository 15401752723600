import React, { useState } from 'react';
import { Paper, Typography, FormControl, LinearProgress } from '@mui/material';
import { useStorage } from '../../hooks/useStorage';
import { Stack, Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import ButtonMain from '../buttons/ButtonMain';
import { colorPackTwo } from '../../theme/theme.js';
import useMediaConverter from '../../hooks/useMediaConverter.js';

const formStyle = {
	display: 'flex',
	flexFlow: 'column noWrap',
	alignContent: 'center',
	alignItems: 'stretch',
	width: '100%',
	maxWidth: '95vw',
	height: '100%',
	maxHeight: '95vh',
	overflow: 'hidden',
};
const imgBox = {
	display: 'flex',
	flexFlow: 'row noWrap',
	width: '90vw',
	justifyContent: 'center',
};

const progressBox = {
	display: 'flex',
	flexFlow: 'column noWrap',
	width: '90vw',
	alignItems: 'stretch',
	justifyContent: 'center',
};

const progressText = {
	display: 'flex',
	flexFlow: 'column noWrap',
	alignItems: 'center',
};

export default function UploadMediaForm({ closeModal }) {
	const { validateAndConvertFiles } = useMediaConverter();
	const [showChooseFile, setShowChooseFile] = useState(true);
	const { addMediaToStorage, progresspercent, uploadCount, ttlUploadCount } =
		useStorage();
	const { capsuleName, subCapsuleName } = useParams();
	const { secondaryMain, primaryLight } = colorPackTwo;
	const [files, setFiles] = useState([]);
	const handleFileChange = (e) => {
        setFiles([...e.target.files]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowChooseFile(false);
        if (files.length) {
            console.log("FILES UPLOADED ARE: ", files);
            const uploadArr = await validateAndConvertFiles(files);
            try {
                addMediaToStorage(uploadArr, capsuleName, subCapsuleName, closeModal);
            } catch (error) {
                console.log('Error uploading media. Error: ', error);
            }
        }
    };
	return (
		<>
			<Paper
				elevation={9}
				style={{ padding: 8, marginLeft: 8, marginRight: 8, maxWidth: '95%vw' }}
			>
				<FormControl>
					{uploadCount > 0 ? (
						<Typography variant="h5" component="span">
							Complete: {uploadCount} / {ttlUploadCount}
						</Typography>
					) : (
						<div />
					)}
				    <form encType="multipart/form-data" style={formStyle}>
						{showChooseFile && (
							<Paper elevation={9}>
								<Stack gap={2}>
									<Box>
										<label htmlFor="name" />
										<input
                                            type="file"
                                            accept="image/jpg,image/png,image/heic,image/heif,image/jpeg,video/mov,video/mp4,video/hevc,video/H.265, video/H.264"
                                            name="fileList"
                                            multiple
                                            onChange={handleFileChange}
                                            style={{
                                                backgroundColor: primaryLight,
                                                padding: 8,
                                                color: 'white',
                                                fontSize: '1.1rem',
                                            }}
                                        />
									</Box>
									<Box>
										<ButtonMain
											bgColor={secondaryMain}
											color={'white'}
											width="100%"
											type="submit"
											label={'Upload Now'}
											onClick={handleSubmit}
										/>
									</Box>
								</Stack>
							</Paper>
						)}
						{!showChooseFile && (
							<>
								<div style={imgBox}>
									Processing files for upload...almost done.
								</div>
								<div style={progressBox}>
									<LinearProgress />
								</div>
								<div style={progressText}>{progresspercent}%</div>
							</>
						)}
					</form>
				</FormControl>
			</Paper>
		</>
	);
}
