import React from 'react';
import { Typography, Paper } from '@mui/material';
import { Stack, Box } from '@mui/system';
import { colorPack } from '../../theme/theme';
import { motion } from 'framer-motion';
import { useMotion } from '../../hooks/useMotion';
import SouthIcon from '@mui/icons-material/South';

export default function NavBarCard({label, color}) {
    const { primaryMain, secondaryDark } = colorPack;
    const { zoomIn, delaySlideUp } = useMotion();

    return (
            <motion.div
                style={{display: 'flex'}}
                variants={zoomIn}
                initial='hidden'
                animate='visible' 
                exit='exit'
            >
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Paper elevation={0} style={{padding: 8}}>
                    <Box sx={{textAlign:'center'}}>

                        <Typography
                            style={{fontSize: '1rem'}} 
                            component='span'
                            color={() => (color ? {color} : primaryMain)}
                        >
                            {label} 
                        </Typography>
                    </Box>
                    </Paper>
                    <motion.div
                        variants={delaySlideUp}
                        initial='hidden'
                        animate='visible' 
                        exit='exit'
                    >
                        <SouthIcon fontSize='large' style={{color: secondaryDark}} />
                    </motion.div>
                </Stack>
            </motion.div>
    )
}

