import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BottomNavigationAction, Paper, BottomNavigation } from '@mui/material';
import CapsuleFormModal from '../forms/CapsuleFormModal';
import NavHomeIcon from './NavHomeIcon';
import NavNewCapsuleIcon from './NavNewCapsuleIcon';
import NavFireList from './NavFireList';

const paperStyle = {
	position: 'fixed',
	top: 'auto',
	bottom: 0,
	margin: 0,
	paddingTop: 0,
	left: 0,
	right: 0,
	width: '100vw',
	borderTop: '1px solid grey',
	backgroundColor: 'grey',
	height: 60,
};

export default function BottomNav() {
	const navigate = useNavigate();
	const [value, setValue] = useState();
	const [showCapsuleForm, setShowCapsuleForm] = useState(false);
	function handleShowCapsuleForm() {
		setShowCapsuleForm(true);
	}

	function handleHideCapsuleForm() {
		setShowCapsuleForm(false);
	}

	function handleClickCapsule() {
		navigate('/');
	}

	function handleClickNew() {
		setShowCapsuleForm(true);
	}

	function handleClickFire() {
		navigate(`/favourites`);
	}

	return (
		<>
			{showCapsuleForm && (
				<CapsuleFormModal
					openModal={handleShowCapsuleForm}
					closeModal={handleHideCapsuleForm}
					isOpen={showCapsuleForm}
				/>
			)}
			<Paper elevation={9} style={paperStyle}>
				<BottomNavigation
					position="static"
					showLabels
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
					}}
				>
					<BottomNavigationAction
						onClick={handleClickFire}
						label="Favorites"
						icon={<NavFireList />}
						style={{ color: 'rgba(0,0,0,0.6)' }}
					/>
					<BottomNavigationAction
						onClick={handleClickCapsule}
						label="Capsules"
						icon={<NavHomeIcon />}
						style={{ color: 'rgba(0,0,0,0.6)' }}
					/>
					<BottomNavigationAction
						onClick={handleClickNew}
						label="New"
						icon={<NavNewCapsuleIcon />}
						style={{ color: 'rgba(0,0,0,0.6)' }}
					/>
				</BottomNavigation>
			</Paper>
		</>
	);
}
