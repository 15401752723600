import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Stack } from '@mui/material'; 
import QuoteTile from '../components/tiles/QuoteTile';
import QuoteFormModal from '../components/forms/QuoteFormModal';
import CursiveTitleCard from '../components/cards/CursiveTitleCard';
import FabButton from '../components/buttons/FabButton';
import { AuthContext } from '../context/AuthProvider';
import { useAsync } from '../hooks/useAsync';
import { useQuote } from '../hooks/useQuote';
import FabTrainingCard from '../components/Training/FabTrainingCard';
import { themeBreakPoints } from '../theme/theme';
import { UserPrefsContext } from '../context/UserPrefsProvider.js';

export default function QuotesPage() {
    const { uid } = useContext(AuthContext); 
    const { capsuleName } = useParams(); 
    const [ quoteData, setQuoteData] = useState([]);
    const [ showQuoteEntryForm, setShowQuoteEntryForm ] = useState(false);
    const { quotes, fetchAllQuotes } = useQuote(); 
    const [showTraining, setShowTraining] = useState(false);
	const { newUserTrainingVisible } = useContext(UserPrefsContext);

    const [ asyncState, setAsyncState] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const loadingState = useAsync(asyncState); 
    
    const handleCloseModal = () => setShowQuoteEntryForm(false);
    
    useEffect(() => {
        if(quotes?.length === 0){
            setAsyncState('pending');
            setShowLoader(true);
            fetchAllQuotes(capsuleName);
        } 
        if(quotes !== null && quotes?.length > 0){
            setAsyncState('success');
            setShowLoader(false);
            setQuoteData(quotes);
        }
        if(quotes === null){
            setAsyncState('success');
            setShowLoader(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[quotes, uid, fetchAllQuotes]);
    useEffect(() => {
        newUserTrainingVisible && setShowTraining(true);
        !newUserTrainingVisible && setShowTraining(false);
    }, [newUserTrainingVisible]);
return (
        <Stack 
            direction='column' 
            gap='2' 
            alignItems='stretch' 
            justifyContent='center'
        >
            <Paper elevation={0} sx={{maxWidth:themeBreakPoints, p:1}}>
                <CursiveTitleCard 
                    title={`${capsuleName} Quotes`}
                />
                {showLoader && loadingState}
                <QuoteFormModal 
                    isOpen={showQuoteEntryForm} 
                    closeModal={handleCloseModal}
                />
                {(showTraining && quoteData?.length === 0) && 
                    <FabTrainingCard message={"Click To Add A New Quote"} />
                }
                <FabButton 
                    onClick={() => setShowQuoteEntryForm(true)} 
                    style={{backgroundColor:'red'}} 
                    size="small" 
                    aria-label="add" 
                />
                <QuoteTile quoteData={quoteData} />
            </Paper>
        </Stack>
    )
}