import React from 'react';
import { Box, Paper, Stack } from '@mui/material';
import FavouritesIcon from '../buttons/FavouritesIcon';
import useFavourite from '../../hooks/useFavourite';
import JournalComp from './JournalComp';

export default function JournalCard({
        id, 
        favourite, 
        capsuleName,
        title, 
        entry, 
        date, 
}){
    const {toggleAllFavourites, toggleJournalStatus} = useFavourite(); 
    const cardType = 'journalCard';
    
    const handleToggleFavourites = async () => {
            const options = {title, entry, date, capsuleName, id};
            try {
                    await toggleAllFavourites(options, favourite, cardType);
                    await toggleJournalStatus(capsuleName, favourite, id);
            } catch (error) {
                    console.log("Error toggling favourites.  Error: ", error);        
            }
    }
    
    return (
        <Paper elevation={3} sx={{p:1}}>
            <Stack gap='2'>
                <Box sx={{position: 'relative', ml: 'auto'}}>
                    <FavouritesIcon 
                            favourite={favourite}
                            onClick={handleToggleFavourites}
                    />
                </Box>
                <JournalComp
                    title={title}
                    entry={entry}
                    date={date}
                />
            </Stack>
        </Paper>  
    )
}
