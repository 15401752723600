import React from 'react'
import { Stack, Typography } from '@mui/material'

export default function JournalComp({title, entry, date}) {

   return(
    <>
         <Stack
                    direction="row" 
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Typography variant='body2' component='span'>
                            {date}
                    </Typography> 
                </Stack>

                <Typography variant='h6' component='span'>
                    {title}
                </Typography>
                <Typography variant='body1' component='div' sx={{ml:1,mt:1}}>
                    {entry}
                </Typography>
    </>        
  )
}
