import React from 'react'
import { motion } from 'framer-motion'
import { useMotion } from '../../hooks/useMotion'
import NavBarCardDown from './NavBarCardDown';
import { colorPackTwo } from '../../theme/theme';

export default function FabTrainingCard({message}) {
  const { delayFromRight } = useMotion();
    const { secondaryDark } = colorPackTwo;
    
  return (
        <motion.div 
            style={{position: 'fixed', left: 25, bottom: 260}}
            variants={delayFromRight}
            initial='hidden'
            animate='visible' 
            exit='exit'
        >
            <NavBarCardDown
                label={message}
                color={secondaryDark}
            />
        </motion.div>
    )
}
