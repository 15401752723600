import React from "react"
import AsyncLoadingCard from "../components/cards/AsyncLoadingCard";

//!! ToDo: write async middleware to replace this hook !!  
const AsyncState = ({promiseState}) => {
    return ( 
        <>
        {(promiseState === 'pending') &&
            <AsyncLoadingCard /> 
        }
        {(promiseState === 'error') &&
            <div>Hmmm, something went wrong.  Try refreshing the page. If that doesn't work, 
            please contact support@meCapsules.com and we'll give you a hand.</div>
        }
        </>
    )
}

export const useAsync = (promiseState) => {
    return <AsyncState promiseState={promiseState} /> 
}

