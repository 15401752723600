import React from 'react';
import { Paper, Typography } from '@mui/material';

export default function ImgCardwithText({ txt, url }) {
	return (
		<Paper
			elevation={6}
			sx={{
				m: 0,
				p: 1,
				display: 'flex',
				flexFlow: 'column noWrap',
				justifyContent: 'center',
				alignItems: 'center',
				maxWidth: 440,
			}}
		>
		
				<Paper elevation={6} sx={{p:1}}>
					<img
						src={url}
						alt="me capsules"
						style={{
							width: '90vw',
							maxWidth: 375,
						}}
					/>
				</Paper>

				<Typography
					variant="subtitle1"
					color="primaryDark"
					sx={{ textAlign: 'center', p: 1 }}
					component="div"
				>
					<p>{txt}</p>
				</Typography>
		
		</Paper>
	);
}
