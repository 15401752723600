import React from 'react';
import { Box, Paper, Stack } from '@mui/material';
import CursiveTitleCard from '../components/cards/CursiveTitleCard';
import FavouritesTile from '../components/tiles/favouritesTile';
import themeBreakPoints from '../theme/theme';

export default function FavouritesPage() {
	return (
		<Paper elevation={0} style={{ padding: 8, marginTop: -32 }}>
			<Stack
				direction="row"
				gap="2"
				alignItems="center"
				justifyContent="center"
			>
				<CursiveTitleCard title={'Favorites'} />
			</Stack>
			<Box sx={{ maxWidth: themeBreakPoints, mx: 'auto' }}>
				<FavouritesTile />
			</Box>
		</Paper>
	);
}
