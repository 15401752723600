import React from 'react'
import { colorPackTwo } from '../../theme/theme';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Fab } from '@mui/material';

export const fabStyle = {
    position: 'fixed',
    bottom: 80,
    left: 24,
}

export default function FabButton({onClick}){
    const { secondaryDark } = colorPackTwo;
    return (
        <div style={fabStyle}>
            <Fab style={{backgroundColor: secondaryDark, color: 'white'}} size="small" aria-label="add" >
                <AddCircleOutlineRoundedIcon onClick={onClick} />                
            </Fab>
        </div>
  )
}
