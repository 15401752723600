import React from 'react';
import { Paper, Typography, Stack } from '@mui/material';
import FavouritesIcon from '../buttons/FavouritesIcon';
import { useFavourite } from '../../hooks/useFavourite';

export default function ItemCard({
        id,
        aboutItem,  
        capsuleName,
        date, 
        favourite, 
        fileName,
        title, 
        url, 
}){
        const cardType = 'itemCard';
        const {toggleAllFavourites, toggleItemStatus} = useFavourite(); 

        const handleToggleFavourites = async () => {
                const options = {aboutItem, capsuleName, date, fileName, title, url, id};
                try {
                        await toggleAllFavourites(options, favourite, cardType);
                        await toggleItemStatus(capsuleName, favourite, id);
                } catch (error) {
                        console.log("Error toggling favourites.  Error: ", error);        
                }
        }

  return (
    <Paper elevation={3} style={{padding: 8}}>
        <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
            >
        <Typography variant='h6' component='h6'>
                {title} 
        </Typography>
        <FavouritesIcon 
                favourite={favourite}
                onClick={handleToggleFavourites}
        />
        </Stack>
        <img src={url} width='100%' height='100%' alt='uploaded' /> 
        <Typography variant='body1' component='div' style={{marginLeft:8, marginTop: 8}}>
                {aboutItem}
        </Typography>
        <Typography style={{display: 'flex', justifyContent: 'flex-end'}} variant='body1' component='h6'>
                {date}
        </Typography>
    </Paper>  
    )
}
