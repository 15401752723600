import React from 'react';
import { Stack } from '@mui/system';
import NavBarCardSide from './NavBarCardSide';

export default function MenuBarTrainingSideNav() {
	return (
		<>
			<Stack direction="column" spacing={0.5} style={{ width: 390 }}>
				<NavBarCardSide label={'Add Journal Entries'} />
				<NavBarCardSide label={'Add Quotes'} />
				<NavBarCardSide label={'Add Items'} />
				<NavBarCardSide label={'Add Photos & Videos'} />
			</Stack>
		</>
	);
}
