import React, { useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';


const loaderStyle = {
  display: 'flex',
  flexFlow: 'column noWrap',
  alignItems: 'center',
  justifyContent: 'stretch',
  width: '340',
  margin: 'auto',
  marginTop: 120, 
}

export default function LoadingCard() {
  const [showLoader, setShowLoader] = useState(false);
  
  const delayLoader = () => {
    setTimeout(() => {
      setShowLoader(true);
    }, 1500);
  }

  //avoids flicker effect when data is loaded quickly & only shows loader when proper delayed. 
  //ToDo:  Replace with useDeferredValue hook when moved from experimental 
  useEffect(() => {
    delayLoader(); 
  },[]);

  return (
    <>
        {showLoader && 
          <Stack style={loaderStyle} spacing={2} direction="column">
            <Box>
                Just finishing up...
            </Box>
            <Box>
              <CircularProgress color="secondary" />
            </Box>
          </Stack>
        }

      </>
    );
}