import React from 'react';
import { Box, Modal } from '@mui/material';
import CreateCapsuleForm from './CreateCapsuleForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 400,
  padding: 1,
  zIndex: 100,
};

export default function CapsuleFormModal({closeModal, isOpen}) {
  const handleClose = () => {
    setTimeout(() => {
      closeModal(); 
    }, 1000);
  }

  return (
    <div>
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <CreateCapsuleForm closeModal={handleClose} />
        </Box>
      </Modal>
    </div>
  );
}