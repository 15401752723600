import React from 'react';
import { Typography, Paper } from '@mui/material';
import { Stack } from '@mui/system';
import { colorPack } from '../../theme/theme';
import { motion } from 'framer-motion';
import { useMotion } from '../../hooks/useMotion';
import EastIcon from '@mui/icons-material/East';

export default function NavBarCardSide({ label }) {
	const { primaryMain, secondaryDark } = colorPack;
	const { zoomIn, delayFromLeft } = useMotion();

	return (
		<motion.div
			variants={zoomIn}
			initial="hidden"
			animate="visible"
			exit="exit"
			id="motion div here"
		>
			<Stack
				direction="row"
				justifyContent="flex-end"
				alignContent="center"
				spacing={2}
			>
				<Paper elevation={0} style={{ padding: 4 }}>
					<Typography
						style={{ fontSize: '0.95rem' }}
						component="span"
						color={primaryMain}
					>
						{label}
					</Typography>
				</Paper>
				<motion.div
					variants={delayFromLeft}
					initial="hidden"
					animate="visible"
					exit="exit"
				>
					<EastIcon fontSize="large" style={{ color: secondaryDark }} />
				</motion.div>
			</Stack>
		</motion.div>
	);
}
