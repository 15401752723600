import React from 'react';
import NavBarCard from './NavBarCard';
import { Stack } from '@mui/system';
import { colorPack } from '../../theme/theme';

export default function MenuBarTrainingTile() {
    const { secondaryDark } = colorPack;

    return (
            <Stack
                direction="row"
                spacing={3}
                style={{maxWidth: 440}}
                sx={{display:'flex', flexFlow: 'row noWrap', justifyContent:'space-around'}}

            >
                <NavBarCard
                    label={"View Your Favourites Here"}
                />
                <NavBarCard
                    label={"View Your Capsules Here"}
                />
                <NavBarCard
                    label={"Click Here To Create A New Capsule"}
                    color={secondaryDark}
                />
            </Stack> 
    )
}
