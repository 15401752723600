import React, { useState } from 'react';
import useDelete from '../../hooks/useDelete';
import {
	Paper,
	FormControl,
	TextField,
	Box,
	Typography,
	Stack,
} from '@mui/material';
import { theme } from '../../theme/theme';
import ButtonMain from '../buttons/ButtonMain';

export default function DeleteCapsuleForm({ closeModal, capsuleName }) {
	const [capsuleToDelete, setCapsuleToDelete] = useState('TYPE CAPSULE NAME');
	const { deleteCapsule } = useDelete();

	const handleSubmit = async (e) => {
		e.preventDefault();
		capsuleToDelete === undefined ??
			console.log('capsuleToDelete is Undefined.');
		if (capsuleToDelete === capsuleName) {
			try {
				deleteCapsule(capsuleToDelete)
					.then(() => closeModal())
					.catch((error) =>
						console.log('Error Deleting Capsule. Error: ', error)
					);
			} catch (error) {
				console.log('Error creating new capsule.', error);
			}
		} else {
			console.log('UNABLE TO DELETE CAPSULE');
			closeModal();
		}
	};

	return (
		<>
			<Stack>
				<Box>
					<Typography
						variant="subtitle1"
						component="p"
						color="darkRed"
						sx={{ p: 1, mb: 1 }}
					>
						WARNING: Deleting a capsule is permanent and CANNOT be undone.
					</Typography>
				</Box>
				<Box sx={{ mb: 2, border: '1px solid darkRed', borderRadius: '4px' }}>
					<ButtonMain
						onClick={closeModal}
						width={'100%'}
						type="submit"
						color={'white'}
						bgColor={theme.palette.primary.main}
						label={'Cancel'}
					/>
				</Box>
				<Paper elevation={9} sx={{ p: 1, backgroundColor: 'grey' }}>
					<Box sx={{ mt: 2 }}>
						<FormControl fullWidth>
							<TextField
								id="capsuleToDelete"
								sx={{ marginBottom: 1, backgroundColor: 'white' }}
								onChange={(e) => setCapsuleToDelete(e.target.value)}
								value={capsuleToDelete}
								variant="outlined"
							/>
						</FormControl>
					</Box>
					<Box sx={{ mb: 2, mt: 2 }}>
						<ButtonMain
							width={'100%'}
							type="submit"
							onClick={handleSubmit}
							color={'white'}
							bgColor={theme.palette.primary.main}
							label={'** Delete Forever **'}
						/>
					</Box>
				</Paper>
			</Stack>
		</>
	);
}
