import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Paper } from '@mui/material';
import { Stack, Box } from '@mui/system';
import { colorPack } from '../../theme/theme';
import { motion } from 'framer-motion';
import { useMotion } from '../../hooks/useMotion';
import CapsuleFormModal from '../forms/CapsuleFormModal';
import EditIcon from '@mui/icons-material/Edit';
import EastIcon from '@mui/icons-material/East';

const { primaryMain, secondaryLight } = colorPack;

const btnContainer = {
	display: 'flex',
	justifyContent: 'center',
};
const btnBox = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 185,
	height: 85,
};
const paper = {
	backgroundColor: secondaryLight,
	zPosition: '-1',
	height: 220,
	width: 390,
	maxWidth: '95vw',
	margin: 'auto',
	padding: 8,
};

export default function NoCapsuleCard() {
	const { zoomIn } = useMotion();
	const [showCapsuleForm, setShowCapsuleForm] = useState(false);
	const [linkRoute] = useState('');

	function handleClickNew() {
		setShowCapsuleForm(true);
	}
	function handleShowCapsuleForm() {
		setShowCapsuleForm(true);
	}
	function handleHideCapsuleForm() {
		setShowCapsuleForm(false);
	}

	return (
		<>
			{showCapsuleForm && (
				<CapsuleFormModal
					openModal={handleShowCapsuleForm}
					closeModal={handleHideCapsuleForm}
					isOpen={showCapsuleForm}
				/>
			)}
			<motion.div
				style={{ padding: 0, overflowY: 'hidden', overflowX: 'hidden' }}
				variants={zoomIn}
				initial="hidden"
				animate="visible"
				exit="exit"
			>
				<Paper elevation={9} style={paper}>
					<Stack
						direction="column"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Box sx={{ textAlign: 'center' }}>
							<Typography
								variant="body1"
								component="span"
								style={{ color: 'white' }}
							>
								Let's learn how to get around in meCapsules.
							</Typography>
						</Box>
						<Box>
							<div style={btnContainer}>
								<Link to={linkRoute} style={{ textDecoration: 'none' }}>
									<motion.div
										whileHover={{ scale: 1.1 }}
										whileTap={{ scale: 0.8 }}
									>
										<Paper
											elevation={6}
											style={btnBox}
											onClick={handleClickNew}
											sx={{ textAlign: 'center' }}
										>
											<Typography
												variant="body1"
												component="span"
												color={primaryMain}
											>
												Enter Capsules By Clicking Here
											</Typography>
										</Paper>
									</motion.div>
								</Link>
							</div>
						</Box>
						<Box sx={{ width: '100%' }}>
							{' '}
							{/* Ensure the Box takes full width */}
							<Stack
								direction="row"
								gap={1}
								justifyContent="flex-end"
								alignItems="center"
								sx={{ width: '100%', color: 'white' }}
							>
								<Typography
									variant="body2"
									textAlign="right"
									color={primaryMain}
								>
									Click This Edit Icon To Change The Background Image or Delete
									A Capsule
								</Typography>
								<EastIcon sx={{ color: 'white' }} />
								<EditIcon
									sx={{
										color: primaryMain,
										borderRadius: '50%', // Creates a circular shape
										border: '0.5px solid white', // White border, adjust thickness as needed
										padding: '4px', // Adjust padding to control the size of the circle around the icon
									}}
								/>
							</Stack>
						</Box>
					</Stack>
				</Paper>
			</motion.div>
		</>
	);
}
