import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	signInWithPopup,
	getAuth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	GoogleAuthProvider,
	onAuthStateChanged,
	browserSessionPersistence,
} from 'firebase/auth';
import { app } from '../services/firebase';
import { userAuthToken, isSession } from '../constants/constants';
import { AuthContext } from '../context/AuthProvider';

export const useAuth = () => {
	const authContext = useContext(AuthContext);
	const { login, logout, storeUid, storeUser } = authContext;
	const storage = window.sessionStorage;
	const auth = getAuth(app);
	const navigate = useNavigate();

	function loginService(uid, user) {
		try {
			storeUid(uid);
			storeUser(user);
			login();
			storage.setItem(isSession, 'true');
			navigate('/');
			return;
		} catch (error) {
			console.log('Error logging in user. Error: ', error);
		}
	}

	const signUpWithEmailAndPassword = (formData) => {
		const { email, password, displayName } = formData;
		createUserWithEmailAndPassword(auth, email, password, displayName)
			.then((userCredential) => {
				// Signed in
				const user = userCredential?.user;
				const uid = userCredential?.user?.uid;
				loginService(uid, user);
			})
			.catch((error) => {
				alert(error.message);
				console.log(
					'Error Signing Up with Email & Password. Error Message: ',
					error.message
				);
			});
	};

	const signInWithEmailPassword = (formData) => {
		const { email, password } = formData;
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				const user = userCredential?.user;
				const uid = userCredential?.user?.uid;
				loginService(uid, user);
			})
			.catch((error) => {
				alert('Incorrect Username or Password.');
				console.log(
					'Error Signing Up with Email & Password. Error Message: ',
					error.message
				);
			});
	};

	/**
	 * will authenticate a user using the Google Authentication service
	 * @returns an auth Obj containing the user, auth data, & an oAuth Token which is set in session storage
	 */
	const googleSignIn = async () => {
		const provider = new GoogleAuthProvider();
		onAuthStateChanged(auth, (User) => {
			if (User) {
				storeUser(User);
				User.getIdToken(false)
					.then(() => {
						const uid = User?.uid;
						const user = User;
						return loginService(uid, user);
					})
					.catch((error) =>
						console.log('Error refreshing session token. Error:', error)
					);
			} else {
				auth
					.setPersistence(browserSessionPersistence)
					.then(() => signInWithPopup(auth, provider))
					.then((result) => {
						const credential = GoogleAuthProvider.credentialFromResult(result);
						const oAuthToken = credential?.accessToken;
						storage.setItem(userAuthToken, oAuthToken);
						const user = result?.user;
						const uid = user?.uid;
						return loginService(uid, user);
					})
					.catch((error) => console.log('Error signing in. Error:', error));
			}
		});
	};

	/**
	 * will sign out the active user and clear the browswer session
	 */
	const signOut = () => {
		try {
			logout();
		} catch (error) {
			console.log('Error signing out user.  Error: ', error);
		}
	};

	/**
	 * will check session storage to see if there is an active user session & updates the authContext
	 * @returns true if a session is found
	 */
	const validateToken = () => {
		let token = window.sessionStorage.getItem(isSession);
		if (token?.length > 0) {
			googleSignIn();
			return true;
		}
		return false;
	};

	return {
		signInWithEmailPassword,
		signUpWithEmailAndPassword,
		validateToken,
		googleSignIn,
		signOut,
	};
};
