import { useState, useContext, useCallback } from 'react';
import { db } from '../services/firebase';
import { query, addDoc, doc, updateDoc, collection, getDocs, onSnapshot } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { AuthContext } from '../context/AuthProvider';

export const useItem = () => {
    const authContext = useContext(AuthContext);
    const [ userItems, setUserItems] = useState([]);
    const { capsuleName } = useParams(); 
    const uid = authContext.uid;
    const [ itemPreviewProgress, setItemPreviewProgress] = useState(0);



    async function getAllItems(capsuleName){
        const itemRef = collection(db, `users/${uid}/capsules/${capsuleName}/items`);
        const q =  query(itemRef);
        return getDocs(itemRef, q);
    }

    /**
     * will fetch the user's Item collection from the target capsule
     * @returns Promise resolves with an array of results from the Items sub-collection
     */

    const fetchAllItems = useCallback((capsuleName) => {
        (function getItems(){
            getAllItems(capsuleName)
            .then((snapshot) => {
                const snapData = snapshot?.docs.map( doc => ({...doc.data(), id: doc.id}));
                if(snapData?.length === 0){
                    setUserItems(snapData)
                } else {
                    setUserItems(null); 
                }
                const qSub = query(collection(db, `users/${uid}/capsules/${capsuleName}/items`));
                onSnapshot(qSub, (snapData) => {
                    const snap = snapData?.docs.map( doc => ({...doc.data(), id: doc.id}));
                    setUserItems(snap);
                });
            }) 
            .catch((error) => console.log("Error fetching Item Array entries.  Error: ", error));
        })();
    },[uid]);
   
    /**
     * will create new item doc with placeholder for media download Url
     * !! important:  this will ensure item is added properly regardless of if storage or firebase complete their taasks first   
     * @param {Object} itemOptions - field/value pairs to add to the item doc 
     * @returns document id
     */
    const createNewItemDoc = async (itemOptions) => {
        const itemArrRef = collection(db, `users/${uid}/capsules/${capsuleName}/items`);
        let id = itemArrRef.id;
        await addDoc(itemArrRef, itemOptions)
        .then((ref) => id = ref.id)
        .catch((error) => {
            console.log("Error adding Item to array.  Error: ", error);
        })
        return console.log("ID RETURNED IS: ", id); 
    }

     /**
     * will save the selected media to Storage when user submits the UploadItemForm
     * @param {Object} formData - user data input into the UploadItemForm
     */
     const addNewItem = async (formData) => {
        const { aboutItem, title } = formData;
        const blobData = formData.media;
        const fileName = blobData.name;
        const itemOptions = {
            aboutItem: aboutItem,
            capsuleName: capsuleName,
            date: '2000-01-01',
            favourite: false,
            fileName: fileName,
            title: title,
            url: 'placeholder', 
        }
        const itemArrRef = collection(db, `users/${uid}/capsules/${capsuleName}/items`);
        let id = itemArrRef.id;
        await addDoc(itemArrRef, itemOptions)
        .then((ref) => id = ref.id)
        .catch((error) => {console.log("Error adding Item to array.  Error: ", error);
        });
        const storage = getStorage();
        const metadata = {
            customMetadata: {
                'mediaName': fileName,
                'capsuleName': capsuleName,
                'subCapsuleName': "none",
        }};
        const path = `users/${uid}/media/${fileName}`;
        const imageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(imageRef, blobData, metadata);
            uploadTask.on("state_changed",
                (snapshot) => { 
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setItemPreviewProgress(progress);
                },
                (error) => {
                    alert(error);
                },
                async () => {
                    getDownloadURL(imageRef)
                    .then((url) => {
                        const collectionRef = collection(db,`users/${uid}/capsules/${capsuleName}/items`); 
                        const docRef = doc(collectionRef, id);
                        updateDoc(docRef, 'url', url);
                        return
                    })
                    .catch((error) => console.log("Error updating image URL. Error:", error))
            });
        }   

return {
        userItems,
        fetchAllItems,
        addNewItem,
        itemPreviewProgress,
        createNewItemDoc,
    }
}
