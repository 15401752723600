import React, { useState, useEffect } from 'react';
import { Box, Modal, Button, Paper, Typography } from '@mui/material';
import useStorage from '../../hooks/useStorage';
import { colorPack } from '../../theme/theme';
import FavouritesIcon from '../buttons/FavouritesIcon';
import useFavourite from '../../hooks/useFavourite';
import ImageComp from './ImageComp';

const { secondaryLight } = colorPack; 

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', 
    width: 375,
    bgcolor: secondaryLight,
    padding: 1,
}
  
  export default function ImageCard({
    url, 
    favourite, 
    fileName, 
    capsuleName,
    subCapsuleName,
    showDeleteBtn, 
  }){
    
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ showConfirm, setShowConfirm ] = useState(false);
    const { destroyMediaFile } = useStorage();
    const { toggleAllFavourites, toggleMediaStatus } = useFavourite(); 
    const cardType = 'imgCard';
  
    const handleToggleFavourites = async () => {
      let options = {
          url, 
          capsuleName, 
          fileName, 
      }
      if(subCapsuleName?.length > 0){
        options = {...options, subCapsuleName};
      }
      await toggleAllFavourites(options, favourite, cardType);
      await toggleMediaStatus(options, favourite);
    }

  const handleDelete = () => {
    setShowConfirm(true);
  }
  
  const handleConfirm = () => {
        setShowConfirm(false);
        let options = {url, favourite, capsuleName, fileName};
    try {
        (subCapsuleName?.length > 0) ? destroyMediaFile({...options, subCapsuleName})
            : destroyMediaFile(options) 
        setShowConfirm(false);
    } catch (error) {
        console.log("Failed to remove el from media array. Error: ", error);
    }
  }

  const handleCancel = () => {
    setShowConfirm(false);
  }

  const closeDeleteModal = () => {
    setShowConfirm(false);
  }

  useEffect(() => {
    setIsLoaded(true);
    },[]
  );

  return (
    <>
      {(isLoaded && url !== undefined) ? (
          
          <Paper elevation={3} style={{marginTop: 0, maxWidth: 440}}>
            <Paper elevation={3} style={{padding: 4}}>
                <Box sx={{position:'relative', width:'30px',ml:'auto'}}>
                    <FavouritesIcon 
                          favourite={favourite}
                          onClick={handleToggleFavourites}
                    />
                </Box>
                <ImageComp url={url} />
            </Paper>

        {(!showConfirm && showDeleteBtn) && 
                <Button 
                    onClick={handleDelete}
                    variant="contained" 
                    size="small" 
                    color='primary'
                    id='delete'
                > 
                  Delete
              </Button>
          }
          {showConfirm && 
            <>
                <Modal
                  open={showConfirm}
                  onClose={closeDeleteModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography style={{padding: 8, margin: 8}} variant='h5' component='h5'>
                            Caution, this cannot be undone. Are you sure you want to permanently remove this media?
                        </Typography>
                        <Button 
                            onClick={handleCancel}
                            variant="contained" 
                            size="small" 
                            color='secondary'
                            id="cancel" 
                            style={{marginLeft: 8}}
                        >
                            Cancel
                        </Button>
                        <Button 
                            onClick={handleConfirm}
                            variant="contained" 
                            size="small" 
                            color='primary'
                            id="confirm"
                            style={{marginLeft: 8}}
                        >
                            Confirm
                        </Button>
                    </Box>
                </Modal>
            </>
          }
        </Paper>
      ) : (
        <div/>
      )
    }
  </>
    )
}
