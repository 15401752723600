import React from 'react';
import JournalCard from '../cards/JournalCard';
import { Box, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { colorPackTwo } from '../../theme/theme';

export default function JournalTile({journalData}) {
    const { primaryLight } = colorPackTwo;
    const { capsuleName } = useParams(); 
    
return (
        <>  
           {journalData?.length > 0 && 
                <Box sx={{
                    display: 'flex', 
                    flexFlow: 'column noWrap', 
                    justifyContent: 'center',  
                    alignItems: 'stretch',
                    mt:2,
                }}> 
                    <Paper elevation={0} style={{padding:0}}>
                        {journalData?.map((doc, index) => {
                            return (
                                <Paper 
                                    elevation={0} 
                                    sx={{bgcolor: primaryLight, mt:1,p:0.5}} 
                                    key={doc.id}
                                >
                                    <JournalCard
                                        index={index + 1}
                                        id={doc.id}
                                        title={doc.title}
                                        entry={doc.entry}
                                        date={doc.date}
                                        favourite={doc.favourite}
                                        capsuleName={capsuleName}
                                    />
                                </Paper>
                            );
                        })}
                    </Paper>
                </Box>
            }
        </>
    )
}
