import React from 'react';
import ModalMain from './ModalMain';
import QuoteForm from '../forms/QuoteForm';

export default function QuoteFormModal({closeModal, isOpen}) {
    const handleClose = () => closeModal();

    return (
            <ModalMain 
                isOpen={isOpen}
                closeModal={handleClose}
                modalElement={
                    <QuoteForm 
                        closeModal={closeModal} 
                        isOpen={isOpen} 
                    />
                }
            />
  );
}
