import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const inputField = {
	display: 'flex',
	alignItems: 'stretch',
	backgroundColor: 'white',
	borderRadius: 4,
};

export default function FormInputText({ name, control, label }) {
	return (
		<Controller
			name={name}
			type="text"
			control={control}
			rules={{ required: true }}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<TextField
					id={name}
					type="text"
					style={inputField}
					label={label}
					onChange={onChange}
					value={value}
					helpertext={error ? error.message : null}
					error={!!error}
					margin="none"
				/>
			)}
		/>
	);
}
